import React, { createContext, useContext, useEffect, useState } from 'react';
import { GlobalProps } from '@figure/figure-frontlib-gui';
import { CURRENT_WALLET_SESSION_STORAGE_KEY } from 'constant';
import { useIdentity } from 'hooks';
import { WalletService, MarketplaceService, PassportService } from 'services';

type AccountState = {
  currentWalletAddress: string;
  authorizedAddress: string;
};

const SetterContext = createContext<React.Dispatch<React.SetStateAction<AccountState>> | undefined>(undefined);
const StateContext = createContext<AccountState | undefined>(undefined);

const AUTHORIZED_ADDRESS_KEY = 'authorizedAddress';

const AccountContextProvider = ({ children }: GlobalProps) => {
  const { identity } = useIdentity();
  const [accountState, setAccountState] = useState<AccountState>({
    currentWalletAddress: sessionStorage.getItem(CURRENT_WALLET_SESSION_STORAGE_KEY) ?? '',
    authorizedAddress: sessionStorage.getItem(AUTHORIZED_ADDRESS_KEY) ?? '',
  });
  WalletService.useGetAllWallets({
    onSuccess: (allWallets) => {
      if (allWallets.size !== 0) {
        const walletsArray = [...allWallets.values()];
        const { address: defaultAddress } =
          walletsArray.find((wallet) => wallet.default && wallet.ownerUuid === identity.uuid) ?? walletsArray[0];
        const cachedAddress = window.sessionStorage.getItem(CURRENT_WALLET_SESSION_STORAGE_KEY) ?? '';
        const cachedWallet = allWallets.get(cachedAddress);
        const currentWalletAddress = cachedWallet ? cachedAddress : defaultAddress;
        setAccountState((state) => {
          let { authorizedAddress } = state;
          const currentWallet = allWallets.get(currentWalletAddress);

          if (!currentWallet?.shared) {
            authorizedAddress = '';
          } else if (!currentWallet?.authorized.some((account) => account.address === authorizedAddress)) {
            authorizedAddress = currentWallet?.authorized[0].address;
          }

          return {
            ...state,
            currentWalletAddress,
            authorizedAddress,
          };
        });
      }
    },
    enabled: false,
  });
  WalletService.useGetWalletDetailsByAddress(accountState.currentWalletAddress, {
    enabled: !!accountState.currentWalletAddress,
  });
  PassportService.useGetAllAccReqs(accountState.currentWalletAddress, {
    enabled: !!accountState.currentWalletAddress,
  });
  WalletService.useGetOpenOrders(accountState.currentWalletAddress, {
    enabled: !!accountState.currentWalletAddress,
  });
  MarketplaceService.useFunds(accountState.currentWalletAddress, {
    enabled: !!accountState.currentWalletAddress,
  });
  WalletService.useGetDelegatedAssets(accountState.currentWalletAddress, {
    enabled: !!accountState.currentWalletAddress,
  });
  WalletService.useGetWireInRef(accountState.currentWalletAddress, {
    enabled: !!accountState.currentWalletAddress,
  });
  useEffect(() => {
    if (accountState.currentWalletAddress) {
      window.sessionStorage.setItem(CURRENT_WALLET_SESSION_STORAGE_KEY, accountState.currentWalletAddress);
    }
  }, [accountState.currentWalletAddress]);
  useEffect(() => {
    sessionStorage.setItem(AUTHORIZED_ADDRESS_KEY, accountState.authorizedAddress);
  }, [accountState.authorizedAddress]);
  return (
    <StateContext.Provider value={accountState}>
      <SetterContext.Provider value={setAccountState}>{children}</SetterContext.Provider>
    </StateContext.Provider>
  );
};

const useSetAccountState = () => {
  const setter = useContext(SetterContext);
  if (setter === undefined) {
    throw new Error('useSetAccountState must be used within a AccountContextProvider');
  }
  return setter;
};
const useAccountState = () => {
  const state = useContext(StateContext);
  if (state === undefined) {
    throw new Error('useAccountState must be used within a AccountContextProvider');
  }
  return state;
};

export { AccountContextProvider, useSetAccountState, useAccountState };
