import { Suspense, useEffect, useCallback } from 'react';
import { Route, Routes, useLocation, Link } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ModalContextProvider, RouterContextProvider, combineCss, cssVar } from '@figure/figure-frontlib-gui';
import { isFigure } from '@figure/frontlib-private-label-gui';
import { AccessibilityContextProvider } from '@figure/frontlib-accessibility';
import store from 'redux/store';
import { OverlaySpinnerContainer, FullStory } from 'components';
import styled, { css } from 'styled-components';
import { TelemetryContextProvider, AccountContextProvider } from 'contexts';
import LendingContainer from './LendingContainer';
import FigureContainer from './FigureContainer';
import { DashboardSettingsContextProvider } from '../../../pages/Settings/dashboard-settings-context';
import { ProfileService } from '../../../services';
import { useIdentity } from '../../../hooks';

const WrappedLink = styled(Link)`
  ${combineCss()}
`;

const DashboardAppShell = () => {
  const { data: profile, refetch: getProfile } = ProfileService.useGetProfile({ enabled: false });
  const { identity, isLoading, organization } = useIdentity();
  const render = !!identity?.uuid && !!profile?.uuid && !isLoading && organization;

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contentRender = useCallback(() => {
    if (isFigure)
      return (
        <Routes>
          <Route
            path="*"
            element={
              <Provider store={store}>
                <TelemetryContextProvider>
                  <AccountContextProvider>
                    <ModalContextProvider>{render && <FigureContainer />}</ModalContextProvider>
                  </AccountContextProvider>
                </TelemetryContextProvider>
              </Provider>
            }
          />
        </Routes>
      );
    return (
      <Routes>
        <Route
          path="*"
          element={
            <Provider store={store}>
              <TelemetryContextProvider>
                <ModalContextProvider>{render ? <LendingContainer /> : <Spinner width="4rem" />}</ModalContextProvider>
              </TelemetryContextProvider>
            </Provider>
          }
        />
      </Routes>
    );
  }, [organization, render, isFigure]);

  return (
    <AccessibilityContextProvider>
      <RouterContextProvider
        value={{
          Link: WrappedLink,
          useLocation,
        }}
      >
        <Suspense
          fallback={
            <OverlaySpinnerContainer
              isLoading={true}
              bgColor={cssVar('colors.neutral.50')}
              spinnerCss={css`
                position: fixed;
              `}
            />
          }
        >
          <DashboardSettingsContextProvider>
            {!!render && contentRender()}
            <FullStory />
          </DashboardSettingsContextProvider>
        </Suspense>
      </RouterContextProvider>
    </AccessibilityContextProvider>
  );
};

export default DashboardAppShell;
