import { DashboardWalletParams, deleteQueryParam, GlobalProps, queryParams } from '@figure/figure-frontlib-gui';
import { CoinAsObject } from '@provenanceio/wallet-lib';
import { useNavigate } from 'react-router-dom';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { WalletService } from 'services';
import { PassportStatus, WalletAccountType } from 'types';
import { useDashboardLoadingState } from '../../pages/Dashboard/hooks/use-dashboard-loading-state';
import { useGetAllAccReqs } from 'services/passport';
import { useCurrentWalletData } from 'hooks';

export enum Bypass2faType {
  SMS = 'SMS',
  TOTP = 'TOTP',
}

type WalletUiState = {
  latestDisplayPrice?: string; // display price of hash
  currentTransferCoin?: CoinAsObject;
  currentWithdrawCoin?: CoinAsObject;
  currentReturnSharesToIssuerCoin?: CoinAsObject;
  marketplaceCancelAuctionUuid?: string;
  cancelInterestedBidModalUuid?: string;
  /**
   * Edge case where user comes in from dlob, for example, and goes through registration in the Figure Wallet user
   */
  isNewUserCreateWalletInFigureWallet?: boolean;
  /**
   * Bypass 2fa for testing purposes.
   */
  bypassTwofa?: Bypass2faType;
  /**
   * Regulation alerts
   */
  hasNyStateRegulationAlertForHash?: boolean;
  hasNonUsRegulationAlertForHash?: boolean;
  shouldShowRegulationAlert?: boolean;
  noWallets?: boolean;
};

const SetterContext = createContext<React.Dispatch<React.SetStateAction<WalletUiState>> | undefined>(undefined);
const StateContext = createContext<WalletUiState | undefined>(undefined);

const WalletUiContextProvider = ({ children }: GlobalProps) => {
  const navigate = useNavigate();
  const isLoading = useDashboardLoadingState();
  const [walletUiState, setWalletUiState] = useState<WalletUiState>({});
  const { address, walletType, holdings } = useCurrentWalletData();
  const { data: allAccReqs } = useGetAllAccReqs(address);
  const { data: wallets } = WalletService.useGetAllWallets({
    enabled: false,
  });

  const noWallets = wallets && wallets.size === 0;
  const validPassports = useMemo(() => allAccReqs?.filter((passport) => passport.status === PassportStatus.VALID), [allAccReqs]);
  const pendingPassports = useMemo(() => allAccReqs?.filter((passport) => passport.status === PassportStatus.PENDING), [allAccReqs]);
  const {
    openWallet,
    openPendingPasssport,
    openPendingPassport: openPendingPassportParam,
    isNewUserCreateWalletFlow,
  } = queryParams as DashboardWalletParams;
  useEffect(() => {
    if (noWallets) {
      setWalletUiState((state) => ({
        ...state,
        noWallets,
      }));
    }
  }, [noWallets]);

  /**
   * Handle state and country regulations.
   */
  useEffect(() => {
    const hasNyStateRegulationAlertForHash =
      (walletType === WalletAccountType.FIGURE || walletType === WalletAccountType.OLDNET) &&
      validPassports?.some(({ accreditationRequest }) => {
        return (
          accreditationRequest.authorizedSigner &&
          accreditationRequest.authorizedSigner.address.state === 'NY' &&
          accreditationRequest.authorizedSigner.address.country === 'US'
        );
      });
    const hasNonUsRegulationAlertForHash =
      (walletType === WalletAccountType.FIGURE || walletType === WalletAccountType.OLDNET) &&
      validPassports?.some(({ accreditationRequest }) => {
        return accreditationRequest.authorizedSigner && accreditationRequest.authorizedSigner.address.country === 'US';
      });
    const shouldShowRegulationAlert = hasNonUsRegulationAlertForHash || hasNyStateRegulationAlertForHash;
    setWalletUiState((state) => ({
      ...state,
      hasNyStateRegulationAlertForHash,
      hasNonUsRegulationAlertForHash,
      shouldShowRegulationAlert,
    }));
  }, [holdings, validPassports, walletType]);
  /**
   * Handle Create Wallet flow from deep link. i.e. get started with figure wallet from website
   */
  useEffect(() => {
    if (!isLoading && openWallet) {
      navigate('/create-wallet/figure');
      deleteQueryParam('openWallet');
    }
  }, [isLoading, openWallet, address, navigate]);

  /**
   * Handle Create Figure Wallet inside of the Figure Wallet window. i.e. A new user goes
   * directly to dlob. They will go through registration, create figure wallet, security questions
   * (optional) and go back to dlob.
   */
  useEffect(() => {
    if (!isLoading && isNewUserCreateWalletFlow) {
      setWalletUiState((state) => ({
        ...state,
        isNewUserCreateWalletInFigureWallet: true,
      }));
      deleteQueryParam('isNewUserCreateWalletFlow');
    }
  }, [isLoading, isNewUserCreateWalletFlow]);

  useEffect(() => {
    if (!isLoading && ((pendingPassports && pendingPassports.length !== 0) || openPendingPasssport || openPendingPassportParam)) {
      deleteQueryParam('openPendingPasssport');
      deleteQueryParam('openPendingPassport');
    }
  }, [isLoading, openPendingPassportParam, openPendingPasssport, pendingPassports]);

  WalletService.useGetDailyHashPrice({
    onSuccess: (data) => {
      setWalletUiState((state) => ({
        ...state,
        latestDisplayPrice: data.latestDisplayPricePerDisplayUnit,
      }));
    },
  });

  return (
    <StateContext.Provider value={walletUiState}>
      <SetterContext.Provider value={setWalletUiState}>{children}</SetterContext.Provider>
    </StateContext.Provider>
  );
};

const useSetWalletUiState = () => {
  const setter = useContext(SetterContext);
  if (setter === undefined) {
    throw new Error('useSetWalletUiState must be used within a WalletUiContextProvider');
  }
  return setter;
};
const useWalletUiState = () => {
  const state = useContext(StateContext);
  if (state === undefined) {
    throw new Error('useWalletUiState must be used within a WalletUiContextProvider');
  }
  return state;
};

export { WalletUiContextProvider, useSetWalletUiState, useWalletUiState };
