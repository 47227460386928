import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AccountType, ConsentType, SignConsentType } from 'types';
import { AxiosError } from 'axios';
import { FIGURE_ORIGIN, axios } from 'constant';

const CONTEXT = '/service-wallet/secure/api/v1/consent';

export enum CONSENT_QUERIES {
  GET_UNSIGNED_CONSENTS = 'GET_UNSIGNED_CONSENTS',
}

export const useGetUnsignedConsents = (options?: UseQueryOptions<ConsentType[], AxiosError>) =>
  useQuery(
    [CONSENT_QUERIES.GET_UNSIGNED_CONSENTS] as QueryKey,
    async () => {
      const { data } = await axios.get<ConsentType[]>(`${FIGURE_ORIGIN}${CONTEXT}/unsigned`);
      return data;
    },
    options
  );

export const useSignConsent = (options?: UseMutationOptions<unknown, AxiosError, SignConsentType>) =>
  useMutation(async (req) => axios.post<AccountType>(`${FIGURE_ORIGIN}${CONTEXT}/sign`, req), options);
