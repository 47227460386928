import { useGetWalletDetailsByAddress, useGetAllWallets } from 'services/wallet/account';
import { useAccountState } from 'contexts';
import { useGetAllAccReqs } from 'services/passport';
import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

const TIMEOUT = 5000;

export const useDashboardLoadingState = () => {
  const options: UseQueryOptions<any, AxiosError, any> = {
    enabled: false,
  };
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setIsLoading(false), TIMEOUT);
  }, []);
  const { currentWalletAddress } = useAccountState();
  const { data: wallets } = useGetAllWallets(options);
  const { isSuccess: gotAccountDetails } = useGetWalletDetailsByAddress(currentWalletAddress, options);
  const { isSuccess: gotAllAccReqs } = useGetAllAccReqs(wallets?.get(currentWalletAddress)?.address, options);
  useEffect(() => {
    if (wallets) {
      if (wallets.size === 0 || (gotAccountDetails && gotAllAccReqs)) setIsLoading(false);
    }
  }, [wallets, gotAccountDetails, gotAllAccReqs]);

  return isLoading;
};
