import { cssVar, Card as BaseCard, combineCss, GlobalProps, media } from '@figure/figure-frontlib-gui';
import styled, { css } from 'styled-components';

const Card = styled(BaseCard)`
  ${css`
    box-shadow: 0 ${cssVar('spacings.3xs')} ${cssVar('spacings.2xs')} rgba(0, 0, 0, 0.1);
    margin-bottom: ${cssVar('spacings.s')};
    ${combineCss()};

    h6,
    h5 {
      color: ${cssVar('colors.neutral.550')};
      margin-bottom: ${cssVar('spacings.2xs')};
    }
    ${media.MD`
      h6 {
        font-size: ${cssVar('typography.fontSize.l')};
      }
    `}
  `}
`;

const DashboardCard = ({ children, ...rest }: GlobalProps) => {
  return <Card {...rest}>{children}</Card>;
};

export default DashboardCard;
