import { baseUrl } from '@figure/frontlib-onboarding-redux/lib/actions/http-actions';
import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';

import {
  AccountType,
  OmnibusResponse,
  OmnibusWireInRefResponse,
  OmnibusWireOutCreateRequest,
  OmnibusWireOutEmailRequest,
  OmnibusWireOutRefRequest,
  OmnibusWireOutRefResponse,
} from 'types';
import { AxiosInstance, AxiosError } from 'axios';
import { FIGURE_ORIGIN, axios, FIGURE_ORGANIZATION } from 'constant';

const CONTEXT = '/service-wallet/secure/api/v1/omnibus';

export enum OMNIBUS_QUERIES {
  GET_WIRE_IN_REF = 'GET_WIRE_IN_REF',
  GET_OMNIBUS_ACCOUNTS = 'GET_OMNIBUS_ACCOUNTS',
}

export const getWireInRef = async (address: string) => {
  const { data } = await axios.get<OmnibusWireInRefResponse>(`${FIGURE_ORIGIN}${CONTEXT}/wire/in/${address}`);
  return data;
};

export const useGetWireInRef = (address: string | undefined, options?: UseQueryOptions<OmnibusWireInRefResponse, AxiosError>) =>
  useQuery([OMNIBUS_QUERIES.GET_WIRE_IN_REF, address] as QueryKey, () => getWireInRef(address as string), options);

export const useGetOmnibusAccounts = (organization: string, options?: UseQueryOptions<OmnibusResponse[], AxiosError>) =>
  useQuery(
    [OMNIBUS_QUERIES.GET_OMNIBUS_ACCOUNTS, organization] as QueryKey,
    async () => {
      if (organization !== FIGURE_ORGANIZATION) return [];
      const { data } = await (axios as AxiosInstance).get<OmnibusResponse[]>(`${baseUrl()}${CONTEXT}`);
      return data;
    },
    options
  );

export const useCreateOmnibusWireOut = (options?: UseMutationOptions<unknown, AxiosError, OmnibusWireOutCreateRequest>) =>
  useMutation(async (req) => axios.post<AccountType>(`${FIGURE_ORIGIN}${CONTEXT}/wire/out`, req), options);

export const useGetOmnibusWireOutRef = (
  options?: UseMutationOptions<OmnibusWireOutRefResponse, AxiosError, OmnibusWireOutRefRequest & { address: string }>
) =>
  useMutation(async (req) => {
    const { address, ...request } = req;
    const { data } = await axios.post<OmnibusWireOutRefResponse>(`${FIGURE_ORIGIN}${CONTEXT}/wire/out/${address}`, request);
    return data;
  }, options);

export const useSendWireOutConfirmationEmail = (options?: UseMutationOptions<unknown, AxiosError, OmnibusWireOutEmailRequest>) =>
  useMutation(async (req) => axios.post<AccountType>(`${FIGURE_ORIGIN}${CONTEXT}/wire/confirm`, req), options);
