import { FIGURE_ORIGIN } from 'constant';

export const getOrigin = () => {
  const { protocol, hostname, port } = window.location;

  return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
};

export const getRedirectUrl = () => {
  const origin = getOrigin();
  const isFigure = origin.includes(FIGURE_ORIGIN);
  const { href } = window.location;
  const lastCharIsHash = href.slice(-1) === '#';

  const figureRedirectRoot = `${FIGURE_ORIGIN}/login`;
  const privateLabelRedirectRoot = `${origin}/account/login`;
  const root = !isFigure ? privateLabelRedirectRoot : figureRedirectRoot;

  return `${root}?redirectUrl=${encodeURIComponent(lastCharIsHash ? href.slice(0, -1) : href)}`;
};
