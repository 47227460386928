import { useQuery } from '@tanstack/react-query';
import { useModal } from '@figure/figure-frontlib-gui';
import { usePassportEntryPoint, PASSPORT_ENTRYPOINTS, PASSPORT_ENTRY_POINT_NAMES } from '@figure/frontlib-investor-onboarding';
import { useAccountState } from 'contexts';
import { useBroadcast } from 'services/wallet/transaction';
import { useWalletService, WINDOW_MESSAGES } from '@provenanceio/wallet-lib';
import useTwoFactorModal from 'hooks/useTwoFactorModal';
import { handleQueryError } from 'utils';
import { axios, PASSPORT_API_BASE } from 'constant';
import { useIdentity } from 'hooks';
import { useGetAllWallets } from 'services/wallet/account';
import type { AccountType } from 'types';
import SubmittingModal, { ID as SUBMITTING } from 'components/gui/Modals/SubmittingModal';
import type { IndividualWalletsResponse } from './types';

export const useGetWalletByAddress = (address: string): AccountType | undefined => {
  const { data: wallets } = useGetAllWallets({
    enabled: false,
  });

  return wallets?.get(address);
};

export const useGetCurrentWallet = () => {
  const { currentWalletAddress } = useAccountState();
  return useGetWalletByAddress(currentWalletAddress);
};

type ConfirmObject = {
  messages: string[];
  title: string;
  description: string;
  onError: (e: Error) => void;
};

const useCompleteProvenanceTransaction = (name: string, address: string) => {
  const { walletService } = useWalletService(import.meta.env.VITE_PROVENANCE_WALLET_URL!);

  return ({ messages, description, onSuccess, onError }: ConfirmObject & { onSuccess: (payload: string) => void }) => {
    walletService.initialize({
      address: address as string,
      keychainAccountName: name,
    });

    walletService.transaction({
      msgAnyB64: messages.join('_'),
      memo: description,
    });

    const handleError = () => {
      onError(new Error('You must sign to complete this action.'));
      walletService.removeAllEventListeners();
    };

    walletService.addEventListener(WINDOW_MESSAGES.TRANSACTION_COMPLETE, (data: any) => {
      onSuccess(data.message.signedPayload);
      walletService.removeAllEventListeners();
    });
    walletService.addEventListener(WINDOW_MESSAGES.CLOSE, handleError);
    walletService.addEventListener(WINDOW_MESSAGES.TRANSACTION_FAILED, handleError);
  };
};

export const useConfirmAction = (address: string) => {
  const { name, walletType } = useGetWalletByAddress(address) ?? {};

  const completeProvenanceTransaction = useCompleteProvenanceTransaction(name!, address!);
  const broadcast = useBroadcast();
  const openTwoFactor = useTwoFactorModal();

  return {
    confirm(options: ConfirmObject & { onSuccess: () => void }) {
      if (walletType !== 'PROVENANCE') {
        openTwoFactor({
          onSuccess: (payload) => {
            broadcast.mutate(
              {
                address: address!,
                msgAnyB64: options.messages.join('_'),
                ...payload,
              },
              {
                onSuccess: () => {
                  options.onSuccess();
                },
                onError: (e) => {
                  options.onError(new Error(handleQueryError(e)));
                },
              }
            );
          },
          onError: options.onError,
          title: options.title,
        });
      } else {
        completeProvenanceTransaction(options);
      }
    },
    isLoading: broadcast.isLoading,
  };
};

/**
 * returns an array of individual wallets
 */
export const useGetIndividualWallets = () => {
  const { jwt } = useIdentity();

  return useQuery(['individualWallets', jwt], () =>
    axios
      .get<IndividualWalletsResponse>(`${PASSPORT_API_BASE}/v3/wallets/identity/individual/valid-or-pending`)
      .then(({ data }) => data.wallets)
  );
};

export const useAddNewEntity = (uuid: string) => {
  const { autoCreateKYC } = usePassportEntryPoint();
  const { openDynamic } = useModal(SUBMITTING);

  return () => {
    openDynamic(<SubmittingModal />, 'root');

    autoCreateKYC({
      ...PASSPORT_ENTRYPOINTS[PASSPORT_ENTRY_POINT_NAMES.DASHBOARD_RBAC_ACCEPTANCE],
      pathVariables: { invitationUuid: uuid },
    });
  };
};
