import { ThemeProvider } from '@figure/figure-frontlib-gui';
import { theme, PrivateLabelQueryContextProvider, Favicon } from '@figure/frontlib-private-label-gui';

const ThemeContextProvider = ({ children }: any) => (
  <PrivateLabelQueryContextProvider>
    <ThemeProvider theme={theme || undefined}>
      <Favicon />
      {children}
    </ThemeProvider>
  </PrivateLabelQueryContextProvider>
);

export { ThemeContextProvider };
