import { FundDetail, FundTransaction, TRANSACTION_TYPE } from 'types';
import { FUND_READABLE, STATUS_READABLE } from 'pages/Dashboard/Marketplace/constants';
import { formatMoney } from '@figure/frontlib-util/lib/numeric-formatters';
import { format } from 'date-fns';
import { getOpenOrderLinks } from 'services/marketplace/links';
import { toCapitalCase } from '@figure/figure-frontlib-gui';
import accounting from 'accounting';

export type MarketplaceOpenOrder = {
  uuid: string;
  type: string;
  date: string;
  quantity?: string;
  amount: string;
  status: string;
  asset: string;
};

export const parseOpenOrder = (fund: FundDetail, order: FundTransaction) => {
  const openOrder = Object.keys(order).reduce<MarketplaceOpenOrder>((orderDisplay, key) => {
    switch (key) {
      case 'type':
        // eslint-disable-next-line no-param-reassign
        orderDisplay.type = FUND_READABLE[order[key]];
        break;
      case 'date':
        // eslint-disable-next-line no-param-reassign
        orderDisplay.date = format(new Date(order[key]), 'MM/dd/yyyy');
        break;
      case 'title':
        // eslint-disable-next-line no-param-reassign
        orderDisplay.asset = order[key];
        break;
      case 'quantity':
        if (fund.type === TRANSACTION_TYPE.PRIVATE_REIT)
          // eslint-disable-next-line no-param-reassign
          orderDisplay.quantity = accounting.formatNumber(order[key], undefined, ',');
        break;
      case 'amount':
        // eslint-disable-next-line no-param-reassign
        orderDisplay.amount = formatMoney(order[key], 2);
        break;
      case 'status':
        // eslint-disable-next-line no-param-reassign
        orderDisplay.status = toCapitalCase(STATUS_READABLE[order[key]] || order[key] || '');
        break;
      case 'uuid':
        // eslint-disable-next-line no-param-reassign
        orderDisplay.uuid = order[key];
        break;
      default:
        break;
    }
    return orderDisplay;
  }, {} as MarketplaceOpenOrder);
  return {
    order: openOrder,
    links: getOpenOrderLinks(order.status, fund),
  };
};
