import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { HelmetProvider } from 'react-helmet-async';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Configuration } from 'rollbar';
import App from './App';
import { IS_DEV, IS_PROD, axios } from 'constant';
import { getToken, setToken } from 'services/identity';

const rollbarConfig: Configuration = {
  accessToken: import.meta.env.VITE_ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: IS_PROD ? 'production' : 'development',
    client: {
      javascript: {
        source_map_enabled: !IS_DEV,
        code_version: import.meta.env.VITE_CODE_VERSION,
        guess_uncaught_frames: true,
      },
    },
  },
  enabled: !IS_DEV,
};

const root = createRoot(document.getElementById('root')!);

const render = () =>
  root.render(
    <StrictMode>
      <RollbarProvider config={rollbarConfig}>
        <HelmetProvider>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </HelmetProvider>
      </RollbarProvider>
    </StrictMode>
  );

/**
 * For auto login to work properly in dev, we need to hit the v2
 * endpoint, which is gated by Google ReCaptcha. This adds
 * the script, waits for load, gets the token, and logs in.
 */
async function setUpDev() {
  const reCaptchaKey = import.meta.env.VITE_RECAPTCHA_KEY;

  const token = await new Promise<string>((resolve) => {
    const style = document.createElement('style');
    style.innerHTML = '.grecaptcha-badge{visibility:hidden}';
    document.head.appendChild(style);
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${reCaptchaKey}`;
    script.addEventListener('load', () => grecaptcha.ready(() => grecaptcha.execute(reCaptchaKey).then(resolve)));

    document.head.appendChild(script);
  });

  axios
    .post(
      'https://test.figure.com/identity/api/v2/login',
      {
        email: import.meta.env.VITE_LOGIN_USER,
        password: import.meta.env.VITE_LOGIN_PW,
      },
      {
        headers: {
          'x-recaptcha-token': token,
        },
      }
    )
    .then(({ data }) => {
      setToken(data.jwt);
      render();
    });
}

if (IS_DEV && !getToken()) {
  setUpDev();
} else {
  render();
}
