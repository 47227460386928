import { memo, useState } from 'react';
import styled, { css } from 'styled-components';
import { cssVar, Div, TriangleDownArrow, flex } from '@figure/figure-frontlib-gui';
import { Document, Page, pdfjs } from 'react-pdf';
import { useResize, usePagination } from 'hooks';
import Download from 'assets/download.svg';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DownloadContainer = styled.section`
  display: flex;
  justify-content: space-between;
`;
const DownloadButton = styled.button`
  display: flex;
  align-items: flex-end;
  color: ${cssVar('colors.neutral.600')};
  svg {
    margin-right: 0.625rem;
  }
`;

const Pagination = styled.section`
  display: flex;
  align-items: center;
`;
const PageSelect = styled.button<{ selected: boolean }>`
  border: none;
  border-bottom: 1px solid ${({ selected }) => (selected ? cssVar('colors.neutral.600') : 'transparent')};
  color: ${cssVar('colors.neutral.600')};
  padding: 0.25rem;
  cursor: pointer;
`;
const PaginationPrevOrNext = styled.button`
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  padding-top: 0.5rem;
  padding-bottom: 0.75rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
`;
const Img = styled.img`
  padding-bottom: 0.3125rem;
  padding-right: 0.5rem;
`;
const MemoPage = memo(Page);

const PDFViewer = ({ file }: { file: string }) => {
  const [pages, setPages] = useState(0);
  const { currentPage, previousPage, nextPage, goToPage } = usePagination();
  const { width: debouncedWidth, containerRef } = useResize();
  return (
    <Div
      $css={css`
        margin-bottom: 1rem;
      `}
    >
      <Div $css={flex('space-between')}>
        <DownloadContainer>
          <DownloadButton type="button" onClick={() => window.open(file)}>
            <Img src={Download} alt="" />
            Download
          </DownloadButton>
        </DownloadContainer>
        <Pagination>
          <PaginationPrevOrNext
            type="button"
            onClick={() => {
              previousPage();
            }}
            disabled={currentPage === 1}
          >
            <TriangleDownArrow
              $css={css`
                transform: rotate(90deg);
              `}
            />
          </PaginationPrevOrNext>
          {[...Array(pages).keys()]
            .map((e) => e + 1)
            .map((pageNumber) => (
              <PageSelect
                type="button"
                key={`page-select-${pageNumber}`}
                onClick={() => {
                  goToPage(pageNumber);
                }}
                selected={currentPage === pageNumber}
              >
                {pageNumber}
              </PageSelect>
            ))}
          <PaginationPrevOrNext
            type="button"
            onClick={() => {
              nextPage();
            }}
            disabled={currentPage === pages}
          >
            <TriangleDownArrow
              $css={css`
                transform: rotate(-90deg);
              `}
            />
          </PaginationPrevOrNext>
        </Pagination>
      </Div>
      <Div
        $css={css`
          background-color: #fff;
          height: 28.125rem;
          overflow: scroll;
        `}
        ref={containerRef}
      >
        <Document file={file} onLoadSuccess={({ numPages }) => setPages(numPages)}>
          <MemoPage pageNumber={currentPage} width={debouncedWidth || 0} />
        </Document>
      </Div>
    </Div>
  );
};

export default PDFViewer;
