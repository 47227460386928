import axios, { AxiosError } from 'axios';
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { format } from 'date-fns';
import { DelegationsResponse, RewardsResponse, UnbondingResponse, UndelegatedUi, Validator, ValidatorUi } from 'types/explorer';

const CONTEXT = `${import.meta.env.VITE_PROVENANCE_EXPLORER_SERVICE_URL}/api/v2`;

export enum EXPLORER_QUERIES {
  GET_DELEGATED_ASSETS = 'GET_DELEGATED_ASSETS',
}

/**
 * Gets delegation info for wallet account. Includes delegated and undelegated assets.
 */
export const useGetDelegatedAssets = (
  accountUuid?: string,
  options?: UseQueryOptions<{ delegatedAssets: ValidatorUi[]; undelegatedAssets: UndelegatedUi[] }, AxiosError>
) =>
  useQuery(
    [EXPLORER_QUERIES.GET_DELEGATED_ASSETS, accountUuid] as QueryKey,
    async () => {
      const [{ data: delegationsRes }, { data: rewardsRes }, { data: unbondingRes }] = await Promise.all([
        // Axios for service-explorer should not use the default figure config
        axios.get<DelegationsResponse>(`${CONTEXT}/accounts/${accountUuid}/delegations`),
        axios.get<RewardsResponse>(`${CONTEXT}/accounts/${accountUuid}/rewards`),
        axios.get<UnbondingResponse>(`${CONTEXT}/accounts/${accountUuid}/unbonding`),
      ]);
      const { results: delegations } = delegationsRes;
      const { records: unbonding } = unbondingRes;
      const { rewards } = rewardsRes;

      const validatorsIdSet = new Set<string>(delegations.concat(unbonding).map((record) => record.validatorSrcAddr));

      const validators = await Promise.all(
        delegations.length || unbonding.length
          ? [...validatorsIdSet.values()].map((id) => axios.get<Validator>(`${CONTEXT}/validators/${id}`).then(({ data }) => data))
          : []
      );

      const validatorsMap = validators.reduce((acc, validator) => {
        acc.set(validator.operatorAddress, validator);
        return acc;
      }, new Map());

      const rewardsMap = rewards.reduce((acc, record) => {
        const firstReward = record.reward[0];

        acc.set(
          record.validatorAddress,
          firstReward
            ? {
                amount: firstReward.amount,
                denom: firstReward.denom,
              }
            : null
        );
        return acc;
      }, new Map());

      const delegatedAssets: ValidatorUi[] = delegations.map((delegator) => {
        const { amount, validatorSrcAddr: validatorId } = delegator;

        return {
          validatorId,
          moniker: validatorsMap.get(validatorId)?.moniker ?? '',
          amount,
          reward: rewardsMap.get(validatorId),
        };
      });

      const undelegatedAssets = unbonding.map((u) => {
        const { amount, block, endTime, validatorSrcAddr: validatorId } = u;

        return {
          validatorId,
          moniker: validatorsMap.get(validatorId)?.moniker ?? '',
          amount,
          block,
          endTime: format(endTime, 'M/d/yyyy h:mm:ss a'),
        };
      });

      return {
        delegatedAssets,
        undelegatedAssets,
      };
    },
    options
  );

export default { useGetDelegatedAssets };
