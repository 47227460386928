import { useEffect, useState } from 'react';
import { handleQueryError } from 'utils';
import { IdentityService } from 'services';

const useIdentity = () => {
  const [error, setError] = useState('');
  const {
    data: identity,
    refetch: getIdentity,
    isLoading,
  } = IdentityService.useGetIdentity({
    enabled: false,
    onError: (e: any) => {
      setError(handleQueryError(e));
    },
  });

  useEffect(() => {
    if (!identity) getIdentity();
  }, []);

  const phoneNumberAliases = identity?.aliases
    ? identity?.aliases.filter(({ type, verified }: any) => type === 'Phone' && verified)
    : [];
  return {
    isLoading,
    error,
    getIdentity,
    identity,
    twoFactorEnabled: identity?.twoFactorEnabled,
    uuid: identity?.uuid,
    aliases: identity?.aliases,
    totpVerified: identity?.totpVerified,
    sms2faVerified: phoneNumberAliases.length !== 0,
    organization: identity?.organization,
    jwt: window.localStorage.getItem('jwt'),
  };
};

export default useIdentity;
