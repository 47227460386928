import { useQuery } from '@tanstack/react-query';
import { FIGURE_ORIGIN, axios } from 'constant';
import { useIdentity } from 'hooks';

function isEmptyObject(obj: any) {
  return JSON.stringify(obj) === '{}' || !obj;
}

// Catch the 401 unauth to avoid redirect to login
// PM uses both jwt cookie and localStorage jwt for figure employees
export const usePMAccessCheck = () => {
  const { jwt } = useIdentity();
  const data = useQuery(
    ['ping', jwt],
    async () => {
      try {
        const { data } = await axios.get(`${FIGURE_ORIGIN}/provenance/portfolio/proxy/v1/ping`);
        return data;
      } catch (e) {
        return {};
      }
    },
    { staleTime: 10 * 60 * 5000 }
  );

  return (
    isEmptyObject(data) ||
    !isEmptyObject(data?.data?.membershipInfo?.memberships) ||
    data?.data?.entityRoles?.length ||
    data?.data?.userRoles?.length
  );
};
