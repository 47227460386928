import { combineReducers, Reducer } from 'redux';
import {
  aalReducer,
  enotaryReducer,
  enotarySchedulingReducer,
  helocReducer,
  identityActions,
  identityReducer,
  piggybackReducer,
  profileConsentTypeReducer,
  profileReducer,
  servicingDrawReducer,
  servicingLoanReducer,
  subscriptionReducer,
} from '@figure/frontlib-onboarding-redux';
import { getRedirectUrl } from 'utils';
import { IS_DEV } from 'constant';

import { servicingStatusReducer, slrReducer, cashoutRefinanceReducer, personalLoanReducer, cryptoLoanReducer } from './reducers';
// https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
export const rootReducer: Reducer = (state: any, action: any) => {
  if (action.type === identityActions.LOGOUT || (IS_DEV && action.type === identityActions.TIMEOUT)) {
    window.location.href = getRedirectUrl();
  }

  return combineReducers({
    aalReducer,
    cashoutRefinanceReducer,
    enotaryReducer,
    enotarySchedulingReducer,
    helocReducer,
    identityReducer,
    personalLoanReducer,
    piggybackReducer,
    profileConsentTypeReducer,
    profileReducer,
    servicingDrawReducer,
    servicingLoanReducer,
    servicingStatusReducer,
    slrReducer,
    subscriptionReducer,
    cryptoLoanReducer,
  })(state, action);
};
