import { FIGURE_ORIGIN } from 'constant';
import { ajaxGet } from '@figure/frontlib-onboarding-redux';

export const GET_USER_CASHOUT_REFINANCES = 'CASHOUT_REFINANCE::GET_USER_CASHOUT_REFINANCES';
export const GET_EXTERNAL_CONDITIONS = 'CASHOUT_REFINANCE::GET_EXTERNAL_CONDITIONS';
export const DOWNLOAD_DOCUMENT = 'CASHOUT_REFINANCE::DOWNLOAD_DOCUMENT';

export const V1_SERVICE_CONTEXT = '/cashout-refinance/secure/api/v1';
export const V1_APPLICATIONS_CONTEXT = `${V1_SERVICE_CONTEXT}/applications`;
export const CONDITIONS_V1_SERVICE_CONTEXT = '/service-conditions/external/api/v1';

/**
 * Gets all mortgage refinance applications for the borrower.
 *
 * @returns {Promise}
 */
const getUserCashoutRefinances = () => async (dispatch: any) =>
  ajaxGet(GET_USER_CASHOUT_REFINANCES, dispatch, `${FIGURE_ORIGIN}${V1_APPLICATIONS_CONTEXT}`);

/**
 * Gets underwriting conditions by application UUID
 *
 * @param {String} uuid Application UUID.
 * @returns {Promise}
 */
export const getExternalConditions = (uuid: string) => async (dispatch: any) =>
  ajaxGet(
    GET_EXTERNAL_CONDITIONS,
    dispatch,
    `${FIGURE_ORIGIN}${CONDITIONS_V1_SERVICE_CONTEXT}/applications/${uuid}/conditions/external-applicants`
  );

/**
 * Downloads the document for the given document UUID.
 *
 * @param {String} uuid Application UUID.
 * @param {String} documentUuid Document UUID.
 * @param {String=} contentDisposition Content disposition response header.
 * @returns {Promise}
 */
export const downloadDocument =
  (uuid: string, documentUuid: string, contentDisposition = 'inline') =>
  async (dispatch: any) =>
    ajaxGet(
      DOWNLOAD_DOCUMENT,
      dispatch,
      `${FIGURE_ORIGIN}${V1_APPLICATIONS_CONTEXT}/${uuid}/documents/${documentUuid}?contentDisposition=${contentDisposition}`,
      {
        responseType: 'arraybuffer',
      }
    );

export default {
  downloadDocument,
  getExternalConditions,
  getUserCashoutRefinances,
};
