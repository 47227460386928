import { cssVar, Div, flex, media } from '@figure/figure-frontlib-gui';
import styled, { css } from 'styled-components';

export const ButtonWrapper = styled(Div)`
  ${css`
    ${flex()};
    flex-wrap: wrap;
    > * {
      min-width: unset;
      width: 100%;
      &:nth-child(2) {
        margin-top: ${cssVar('spacings.xs')};
      }
    }
    ${media.MD`
      ${flex('flex-end')};
      width: 100%;
      > * {
        min-width: 33%;
        width: unset;
        &:nth-child(2) {
          margin-left: ${cssVar('spacings.xs')};
          margin-top: 0;
        }
      }
    `}
  `}
`;
