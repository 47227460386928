import { retryLazyLoad } from '@figure/figure-frontlib-gui';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const LendingDashboard = lazy(retryLazyLoad(() => import('../../../pages/PrivateLabel/Dashboard')));

const LendingContainer = () => (
  <Routes>
    <Route path="*" element={<LendingDashboard />} />
  </Routes>
);

export default LendingContainer;
