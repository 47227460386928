import { FIGURE_ORIGIN } from 'constant';
import { ajaxGet } from '@figure/frontlib-onboarding-redux';

const SECURE = `${FIGURE_ORIGIN}/service-application-pl/secure/api/v1/application`;

export const GET_PERSONAL_LOANS = 'PL::GET_PERSONAL_LOANS';
export const GET_PERSONAL_LOAN_AAL = 'PL::GET_PERSONAL_LOAN_AAL';
export const DOWNLOAD_PERSONAL_LOAN_AAL = 'PL::DOWNLOAD_PERSONAL_LOAN_AAL';

export const getPersonalLoans = () => async (dispatch: any) => ajaxGet(GET_PERSONAL_LOANS, dispatch, `${SECURE}/pl/all`);

export const getPersonalLoanAal = (appUuid: string) => async (dispatch: any) =>
  ajaxGet(GET_PERSONAL_LOAN_AAL, dispatch, `${SECURE}/${appUuid}/adverse-actions`);

export const downloadPersonalLoanAal = (appUuid: string, docUuid: string) => async (dispatch: any) =>
  ajaxGet(DOWNLOAD_PERSONAL_LOAN_AAL, dispatch, `${SECURE}/${appUuid}/adverse-actions/${docUuid}/download`, {
    responseType: 'arraybuffer',
  });

export default {
  getPersonalLoans,
  getPersonalLoanAal,
  downloadPersonalLoanAal,
};
