import { GlobalProps } from '@figure/figure-frontlib-gui';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useIdentity } from 'hooks';
import { useGetAnsweredQuestionsByUser, useGetQuestions } from 'services/settings/security-questions';
import { useGetOmnibusAccounts } from 'services/wallet/omnibus';
import { OmnibusResponse, SecurityQuestion } from 'types';

type DashboardSettingsState = {
  securityQuestions?: SecurityQuestion[][];
  securityQuestionAnswers?: SecurityQuestion[];
  omnibusAccounts?: OmnibusResponse[];
  updated2Fa?: 'UPDATED_SMS' | 'DISABLED_SMS' | 'DISABLED_OTP' | 'RESET_OTP';
  // // Either updating 2fa or setting it up for the first time. Display different success messages.
  isUpdating2Fa?: boolean;
  hasSecurityQuestionsSetup?: boolean;
};

const SetterContext = createContext<React.Dispatch<React.SetStateAction<DashboardSettingsState>> | undefined>(undefined);
const StateContext = createContext<DashboardSettingsState | undefined>(undefined);

const DashboardSettingsContextProvider = ({ children }: GlobalProps) => {
  const { pathname } = useLocation();
  const { organization } = useIdentity();
  const [dashboardSettingsState, setDashboardSettingsState] = useState<DashboardSettingsState>({});
  const { data: securityQuestions } = useGetQuestions();
  const { data: securityQuestionAnswers } = useGetAnsweredQuestionsByUser();
  const { data: omnibusAccounts } = useGetOmnibusAccounts(organization, {
    staleTime: Infinity,
  });
  const hasSecurityQuestionsSetup = useMemo(
    () => securityQuestionAnswers && securityQuestionAnswers?.length > 0,
    [securityQuestionAnswers]
  );
  useEffect(() => {
    if (securityQuestions) {
      setDashboardSettingsState((state) => ({
        ...state,
        securityQuestions,
      }));
    }
  }, [securityQuestions]);
  useEffect(() => {
    if (securityQuestionAnswers) {
      setDashboardSettingsState((state) => ({
        ...state,
        securityQuestionAnswers,
      }));
    }
  }, [securityQuestionAnswers]);
  useEffect(() => {
    if (omnibusAccounts) {
      setDashboardSettingsState((state) => ({
        ...state,
        omnibusAccounts,
      }));
    }
  }, [omnibusAccounts]);
  useEffect(() => {
    if (hasSecurityQuestionsSetup) {
      setDashboardSettingsState((state) => ({
        ...state,
        hasSecurityQuestionsSetup,
      }));
    }
  }, [hasSecurityQuestionsSetup]);
  // Reset update / disable messages after navigating away from settings
  useEffect(() => {
    if (!pathname.includes('settings')) {
      setDashboardSettingsState((state) => ({
        ...state,
        updated2Fa: undefined,
        isUpdating2Fa: undefined,
      }));
    }
  }, [pathname]);

  return (
    <StateContext.Provider value={dashboardSettingsState}>
      <SetterContext.Provider value={setDashboardSettingsState}>{children}</SetterContext.Provider>
    </StateContext.Provider>
  );
};

const useSetDashboardSettingsState = () => {
  const setter = useContext(SetterContext);
  if (setter === undefined) {
    throw new Error('useSetDashboardSettingsState must be used within a DashboardSettingsContextProvider');
  }
  return setter;
};
const useDashboardSettingsState = () => {
  const state = useContext(StateContext);
  if (state === undefined) {
    throw new Error('useDashboardSettingsState must be used within a DashboardSettingsContextProvider');
  }
  return state;
};

export { DashboardSettingsContextProvider, useSetDashboardSettingsState, useDashboardSettingsState };
