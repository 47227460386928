import { handleActions } from 'redux-actions';
import { REQUEST, SUCCESS, FAILURE } from '@figure/frontlib-onboarding-redux/lib/actions/http-actions';
import { mergeList, payloadError } from '@figure/frontlib-onboarding-redux/lib/reducers/util';
import { GET_PERSONAL_LOANS, GET_PERSONAL_LOAN_AAL, DOWNLOAD_PERSONAL_LOAN_AAL } from '../actions/personal-loan-actions';

type PersonalLoansState = {
  personalLoansByUuid: {
    [key: string]: any;
  };
  activeUuid: string;
  socketConnected: boolean;
  loading: boolean;
  error: string;
};

export const initialState = {
  personalLoansByUuid: {
    //  '00000000-0000-0000-0000-000000000000': {
    //    appStatus: '',
    //    autopayAccount: {
    //      accountNumber: '',
    //      accountType: '',
    //      accountTypeDescription: '',
    //      achAccountId: '',
    //      ownerName: '',
    //      routingNumber: '',
    //    },
    //    consentsList: [],
    //    currentLivingArrangement: '',
    //    desiredLoanAmount: 0,
    //    disburseAccount:
    //      uuid,
    //      accountType,
    //      routingNumber,
    //      accountNumber,
    //      ownerName,
    //      achAccountId
    //   },
    //    documents: [],
    //    employmentVerificationNeeded: false,
    //    identityUuid: '',
    //    income: {
    //      incomePrimary: 0,
    //      incomeCoSigner: 0,
    //      incomeOther: 0,
    //    },
    //    incomeSources: [],
    //    loanNumber: '',
    //    payType: 'UNKNOWN',
    //    reason: '',
    //    selectedOffer: {
    //      amount: 0,
    //      aprRate: 0,
    //      rate: 0,
    //      term: 0,
    //      type: '',
    //      originationFeePercentage: 0,
    //      selectedDiscounts: []
    //    },
    //    status: {
    //      applicationUuid: '',
    //      creditScoreDecline: false,
    //      disclosurelistAppReady: false,
    //      disclosurelistClosingComplete: false,
    //      disclosurelistClosingReady: false,
    //      disclosurelistSummaryComplete: false,
    //      disclosurelistSummaryReady: false,
    //      disclosurelistUuid: '',
    //      dtiDecline: false,
    //      employmentVerificationNeeded: false,
    //      giactAccountStatus: '',
    //      hasAal: false,
    //      krangStatus: '',
    //      primaryParticipantAcceptsComplete: false,
    //      primaryParticipantAcceptsReady: false,
    //      softCreditFrozen: false,
    //      softCreditFrozenWithPin: false,
    //      softCreditLocked: false,
    //      softCreditNoMatch: false,
    //      softCreditNoMatchWithSsn: false,
    //      uwDecision: '',
    //      uwSubDecision: '',
    //    },
    //   uuid: '',
    // }
  },
  activeUuid: '',
  socketConnected: false,
  loading: false,
  error: '',
};

const reducer = handleActions(
  {
    [`${GET_PERSONAL_LOANS}_${REQUEST}`](state = initialState) {
      return {
        ...state,
        error: '',
        loading: true,
      };
    },
    [`${GET_PERSONAL_LOAN_AAL}_${REQUEST}`](state = initialState) {
      return {
        ...state,
        error: '',
        loading: true,
      };
    },
    [`${DOWNLOAD_PERSONAL_LOAN_AAL}_${REQUEST}`](state = initialState) {
      return {
        ...state,
        error: '',
        loading: true,
      };
    },
    [`${GET_PERSONAL_LOANS}_${FAILURE}`](state, action) {
      return {
        ...state,
        error: payloadError(action),
      };
    },
    [`${GET_PERSONAL_LOAN_AAL}_${FAILURE}`](state, action) {
      return {
        ...state,
        error: payloadError(action),
      };
    },
    [`${GET_PERSONAL_LOAN_AAL}_${SUCCESS}`](state: PersonalLoansState, action) {
      const personalLoanUuid = (action.payload as any).applicationUuid;
      const personalLoan = state.personalLoansByUuid[personalLoanUuid] || {};
      return {
        ...state,
        personalLoansByUuid: {
          ...state.personalLoansByUuid,
          [personalLoanUuid]: {
            ...personalLoan,
            aalDocs: (action.payload as any).documentsList || [],
          },
        },
      };
    },
    [`${GET_PERSONAL_LOANS}_${SUCCESS}`](state: PersonalLoansState, action) {
      if (!action.payload) {
        return {
          ...state,
          error: '',
          loading: false,
        };
      }

      const personalLoansByUuid = (action.payload as any)
        .map((pl: any) => {
          const personalLoanUuid = pl.applicationUuid;
          const personalLoan = state.personalLoansByUuid[personalLoanUuid] || {};
          const { selectedOffer = {} } = pl;

          return {
            ...personalLoan,
            active: pl.active,
            initialOfferTimestamp: pl.initialOfferTimestamp,
            appStatus: pl.appStatus,
            autopayAccount: {
              ...personalLoan.autopayAccount,
              accountNumber: pl.disburseAccount?.accountNumber || '',
              accountType: pl.disburseAccount?.accountType || '',
              accountTypeDescription: pl.disburseAccount?.accountTypeDescription || '',
              achAccountId: pl.disburseAccount?.achAccountId || '',
              ownerName: pl.disburseAccount?.ownerName || '',
              routingNumber: pl.disburseAccount?.routingNumber || '',
              uuid: pl.autopayAccount?.uuid || '',
            },
            currentLivingArrangement: (action.payload as any)?.currentLivingArrangement || '',
            desiredLoanAmount: (action.payload as any)?.desiredLoanAmount || 0,
            consentsList: mergeList(personalLoan.consentsList, pl.consentsList),
            disburseAccount: {
              ...personalLoan.disburseAccount,
              accountNumber: pl.disburseAccount?.accountNumber || '',
              accountType: pl.disburseAccount?.accountType || '',
              accountTypeDescription: pl.disburseAccount?.accountTypeDescription || '',
              achAccountId: pl.disburseAccount?.achAccountId || '',
              ownerName: pl.disburseAccount?.ownerName || '',
              routingNumber: pl.disburseAccount?.routingNumber || '',
              uuid: pl.disburseAccount?.uuid || '',
            },
            documents: mergeList(personalLoan.documents, pl.documents),
            employmentVerificationNeeded: (action.payload as any)?.employmentVerificationNeeded || false,
            identityUuid: pl.identityUuid,
            income: {
              incomePrimary: pl.income?.incomePrimary || 0,
              incomeCoSigner: pl.income?.incomeCoSigner || 0,
              incomeOther: pl.income?.incomeOther || 0,
            },
            loanNumber: pl.loanNumber,
            payType: pl.payType,
            reason: pl?.reason || '',
            selectedOffer: {
              ...personalLoan.selectedOffer,
              amount: selectedOffer?.amount || 0,
              aprRate: selectedOffer?.aprRate || 0,
              rate: selectedOffer?.rate || 0,
              term: selectedOffer?.term || 0,
              rateType: selectedOffer?.rateType || '',
              originationFeePercentage: selectedOffer?.originationFeePercentage || 0,
              selectedDiscounts: selectedOffer?.selectedDiscounts || [],
              estimatedMonthlyPayment: selectedOffer?.estimatedMonthlyPayment || 0,
            },
            status: {
              ...personalLoan.status,
              applicationUuid: pl.status?.applicationUuid || '',
              creditScoreDecline: pl.status?.creditScoreDecline || false,
              disclosurelistAppReady: pl.status?.disclosurelistAppReady || false,
              disclosurelistClosingComplete: pl.status?.disclosurelistClosingComplete || false,
              disclosurelistClosingReady: pl.status?.disclosurelistClosingReady || false,
              disclosurelistSummaryComplete: pl.status?.disclosurelistSummaryComplete || false,
              disclosurelistSummaryReady: pl.status?.disclosurelistSummaryReady || false,
              disclosurelistUuid: pl.status?.disclosurelistUuid || '',
              dtiDecline: pl.status?.dtiDecline || false,
              employmentVerificationNeeded: pl.status?.employmentVerificationNeeded || false,
              giactAccountStatus: pl.status?.giactAccountStatus || '',
              hasAal: pl.status?.hasAal || false,
              krangStatus: pl.status?.krangStatus || '',
              primaryParticipantAcceptsComplete: pl.status?.primaryParticipantAcceptsComplete || false,
              primaryParticipantAcceptsReady: pl.status?.primaryParticipantAcceptsReady || false,
              softCreditFrozen: pl.status?.softCreditFrozen || false,
              softCreditFrozenWithPin: pl.status?.softCreditFrozenWithPin || false,
              softCreditLocked: pl.status?.softCreditLocked || false,
              softCreditNoMatch: pl.status?.softCreditNoMatch || false,
              softCreditNoMatchWithSsn: pl.status?.softCreditNoMatchWithSsn || false,
              uwDecision: pl.status?.uwDecision || '',
              uwSubDecision: pl.status?.uwSubDecision || '',
            },
            uuid: personalLoanUuid,
          };
        })
        .reduce((result: any, h: any) => {
          const keyValue = {} as any;
          keyValue[h.uuid] = h;

          return { ...result, ...keyValue };
        }, {});

      return {
        ...state,
        personalLoansByUuid: {
          ...state.personalLoansByUuid,
          ...personalLoansByUuid,
        },
        loading: false,
        error: '',
      };
    },
  },
  initialState
);

export default reducer;
