import { AxiosError } from 'axios';
import { UseQueryOptions } from '@tanstack/react-query';

import { useAccountState } from 'contexts';
import { useAggregatedWalletDataByAddress } from 'services/wallet/aggregated-wallet-state';

const useCurrentWalletData = (
  options: UseQueryOptions<any, AxiosError, any> = {
    enabled: false,
  }
) => {
  const { currentWalletAddress } = useAccountState();
  return useAggregatedWalletDataByAddress(currentWalletAddress, options);
};

export default useCurrentWalletData;
