import { createAction } from 'redux-actions';
import { ajaxPost } from '@figure/frontlib-onboarding-redux';
import { FIGURE_ORIGIN } from 'constant';

export const SERVICING_SERVICE_DOWN = 'SERVICING_SERVICE_DOWN';
export const GET_DRAW_ELIGIBILITIES = 'GET_DRAW_ELIGIBILITIES';
export const DOC_UPLOAD = 'DOC_UPLOAD';

export const servicingServiceDown = createAction(SERVICING_SERVICE_DOWN);

export interface FidoDocResponse {
  uuid: string;
}

// Uploads documents and then creates a slack notification
export const uploadDoc = (identityUuid: string, files: Array<File>, dispatch: any) => {
  const url = `${FIGURE_ORIGIN}/service-application/api/v1/applications/upload-docs-from-dashboard`;
  const formData = new FormData();
  formData.append('uuid', identityUuid);
  files.forEach((file) => {
    formData.append('file', file);
  });
  return ajaxPost(DOC_UPLOAD, dispatch, url, formData, { withCredentials: true }, false);
};

export default {
  uploadDoc,
};
