import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { FIGURE_ORIGIN, IS_DEV, axios } from 'constant';
import { getOrigin, getRedirectUrl } from 'utils';

const SECURE_CONTEXT = '/identity/secure/api/v1';
const CONTEXT = '/identity/api/v1';

export enum IDENTITY_QUERIES {
  GET_IDENTITY = 'GET_IDENTITY',
  GET_TOTP_URL = 'GET_TOTP_URL',
}

const useIdentityLogin = (options?: UseMutationOptions<unknown, AxiosError, { email: string; password: string }>) =>
  useMutation((data) => axios.post(`${FIGURE_ORIGIN}${CONTEXT}/login`, data), options);

const useGetIdentity = (options?: UseQueryOptions<any, AxiosError, any, any>) =>
  useQuery(
    [IDENTITY_QUERIES.GET_IDENTITY],
    async () => {
      const { data } = await axios.get<any>(`${FIGURE_ORIGIN}${SECURE_CONTEXT}_1/identity`);
      return data;
    },
    options
  );

/**
 * Verifies an sms token for a given phone alias.
 */
const useSendSmsCode = (options?: UseMutationOptions<unknown, AxiosError, any>) =>
  useMutation((data) => axios.post(`${FIGURE_ORIGIN}${SECURE_CONTEXT}/verification/send`, data), options);
/**
 * Sends the initial verification sms text for 2fa flow. { verificationType, alias }
 */
const useVerifySmsCode = (options?: UseMutationOptions<unknown, AxiosError, any>) =>
  useMutation((data) => axios.put(`${FIGURE_ORIGIN}${SECURE_CONTEXT}/verification/verify`, data), options);

const useUpdatePassword = (options?: UseMutationOptions<unknown, AxiosError, any>) =>
  useMutation((data) => axios.put(`${FIGURE_ORIGIN}${SECURE_CONTEXT}/pw`, data), options);
/**
 * Adds an alias, i.e. phone or email.
 */
const useAddAlias = (options?: UseMutationOptions<unknown, AxiosError, any>) =>
  useMutation((data) => axios.put(`${FIGURE_ORIGIN}${SECURE_CONTEXT}/identity/alias`, data), options);
/**
 * Toggle to enable or disable 2fa.
 */
const useToggleTwoFactor = (options?: UseMutationOptions<unknown, AxiosError, any>) =>
  useMutation((enabled) => axios.put(`${FIGURE_ORIGIN}${SECURE_CONTEXT}/2fa?enabled=${enabled}`), options);

const useGetTotpUrl = (options?: UseQueryOptions<any, AxiosError, any, any>) =>
  useQuery(
    [IDENTITY_QUERIES.GET_TOTP_URL],
    async () => {
      const { data } = await axios.get<any>(`${FIGURE_ORIGIN}${SECURE_CONTEXT}/totp`);
      return data;
    },
    options
  );

const useVerifyTotp = (options?: UseMutationOptions<unknown, AxiosError, any>) =>
  useMutation((data) => axios.post(`${FIGURE_ORIGIN}${SECURE_CONTEXT}/totp/verify`, data), options);

/** Disable SMS 2fa */
const useRevokeVerifiedAlias = (options?: UseMutationOptions<unknown, AxiosError, any>) =>
  useMutation((aliasUuid) => axios.put(`${FIGURE_ORIGIN}${SECURE_CONTEXT}/verification/revoke`, { aliasUuid }), options);

export const getToken = () => localStorage.getItem('jwt');

export const setToken = (jwt: string) => localStorage.setItem('jwt', jwt);

const clearStorage = () => localStorage.clear();

/**
 * Logs out by removing jwt.
 */
const logout = () => {
  localStorage.clear();
  window.location.href = getRedirectUrl();
};

/** Setup axios */

// Custom header to set the Origin for white claw in case client does not include it in default Origin header
axios.defaults.headers.common['X-With-Origin'] = getOrigin();

axios.interceptors.request.use((config) => {
  const jwt = getToken();

  return jwt
    ? {
        ...config,
        headers: {
          ...config.headers,
          Authorization: jwt,
        },
      }
    : config;
});

axios.interceptors.response.use(
  (response: any) => {
    if (response.status === 200) {
      const { authorization: jwt } = response.headers;

      if (typeof jwt === 'string' && jwt.indexOf('Bearer ') === 0) {
        setToken(jwt);
      }
    }

    return response;
  },
  (error: any) => {
    // have to check error object existence due to possibility of cancellations
    if (
      (error?.response?.status === 403 && error?.response?.data?.message?.toUpperCase() === 'UNAUTHENTICATED') ||
      (error?.response?.status === 401 && !error?.response.config?.url.includes('provenance/portfolio/proxy/v1/ping'))
    ) {
      clearStorage();
      // Makes the distinction of redirecting for Figure dashboard vs Private label partner dashboard
      if (!IS_DEV) {
        window.location.href = getRedirectUrl();
      }
    }

    return Promise.reject(error);
  }
);

export default {
  logout,
  clearStorage,
  setToken,
  useAddAlias,
  useGetIdentity,
  useGetTotpUrl,
  useIdentityLogin,
  useRevokeVerifiedAlias,
  useSendSmsCode,
  useToggleTwoFactor,
  useUpdatePassword,
  useVerifySmsCode,
  useVerifyTotp,
};
