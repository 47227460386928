export const FIGURE_ORGANIZATION = 'FIGURE';
export const HOMEBRIDGE_ORGANIZATION = 'HOMEBRIDGE';
export const GUARANTEED_RATE_ORGANIZATION = 'GUARANTEED_RATE';
export const HOMEBRIDGE_WHOLESALE_ORGANIZATION = 'HOMEBRIDGE_WHOLESALE';
export const MOVEMENT_ORGANIZATION = 'MOVEMENT';
export const REMN_WHOLESALE_ORGANIZATION = 'REMN_WHOLESALE';
export const SYNERGY_ONE_ORGANIZATION = 'SYNERGY_ONE';
export const HOMEPOINT_ORGANIZATION = 'HOMEPOINT';
export const LOANLIFE_ORGANIZATION = 'LOANLIFE';
export const NEW_AMERICAN_FUNDING_ORGANIZATION = 'NEW_AMERICAN_FUNDING';
export const FAIRWAY_ORGANIZATION = 'FAIRWAY';
export const GUARANTEED_RATE_AFFINITY_ORGANIZATION = 'GUARANTEED_RATE_AFFINITY';
export const CROSS_COUNTRY_ORGANIZATION = 'CROSS_COUNTRY';
