import { lazy, useEffect } from 'react';
import { retryLazyLoad } from '@figure/figure-frontlib-gui';
import { datadogRum } from '@datadog/browser-rum';
import { Route, Routes } from 'react-router-dom';
import { useIdentity } from '../../../hooks';
import { IS_DEV, IS_PROD } from 'constant';

const Connect = lazy(retryLazyLoad(() => import('../../../pages/FigureWallet/Connect')));
const Transaction = lazy(retryLazyLoad(() => import('../../../pages/FigureWallet/Transaction')));
const Dashboard = lazy(retryLazyLoad(() => import('../../../pages/Dashboard')));
const Sign = lazy(retryLazyLoad(() => import('../../../pages/FigureWallet/Sign')));
/**
 * Isolated 2FA flow that is iframed in MP signing.
 */
const TwoFactor = lazy(retryLazyLoad(() => import('pages/TwoFactor')));
const DeclineInvitation = lazy(retryLazyLoad(() => import('pages/Dashboard/invites/decline')));

if (!IS_DEV) {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DD_APP_ID as string,
    clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN as string,
    site: 'datadoghq.com',
    service: import.meta.env.VITE_DD_SERVICE as string,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    env: IS_PROD ? 'prod' : 'test',
  });
}

datadogRum.startSessionReplayRecording();

const FigureContainer = () => {
  const { identity } = useIdentity();
  /**
   * Datadog user session
   */
  useEffect(() => {
    if (!IS_DEV && identity) {
      datadogRum.setUser({ id: identity.uuid });
    }
  }, [identity]);

  return (
    <Routes>
      <Route path="connect" element={<Connect />} />
      <Route path="transaction" element={<Transaction />} />
      <Route path="sign" element={<Sign />} />
      <Route path="2fa/*" element={<TwoFactor />} />
      <Route path="invites/:uuid/decline" element={<DeclineInvitation />} />
      <Route path="*" element={<Dashboard />} />
    </Routes>
  );
};

export default FigureContainer;
