import { handleActions } from 'redux-actions';
import { REQUEST, SUCCESS, FAILURE } from '@figure/frontlib-onboarding-redux/lib/actions/http-actions';
import { mergeList, payloadError } from '@figure/frontlib-onboarding-redux/lib/reducers/util';
import { GET_EXTERNAL_CONDITIONS, GET_USER_CASHOUT_REFINANCES } from '../actions/cashout-refinance-actions';

type CashoutRefinanceState = {
  cashoutRefinancesByUuid: {
    [key: string]: any;
  };
  loading: boolean;
  error: string;
};

export const initialState = {
  cashoutRefinancesByUuid: {},
  loading: false,
  error: '',
};

const mergeApplicationStatus = (previousStatus: any, status: any) => ({
  ...previousStatus,
  krangStatus: status.krangStatus || '',
  krangOverallState: status.krangOverallState || '',
  uwDecision: status.uwDecision || '',
  uwSubDecision: status.uwSubDecision || '',
  liensReady: status.liensReady || '',
  offersReady: status.offersReady || '',
  dtiDeclined: status.dtiDeclined || '',
  ficoDeclined: status.ficoDeclined || '',
  completedAllDocutechSigning: status.completedAllDocutechSigning || '',
  needsOfferReSelect: status.needsOfferReSelect || '',
  awaitingDUReview: status.awaitingDUReview || '',
  needsManualLienInfo: status.needsManualLienInfo || false,
  borrowerStatus: mergeList(previousStatus?.borrowerStatus, status.borrowerStatus, 'identityUuid'),
  additionalSignerStatus: mergeList(previousStatus?.borrowerStatus, status.borrowerStatus, 'identityUuid'),
  trid: {
    ...previousStatus?.trid,
    loanEstimateSigned: status.trid?.loanEstimateSigned || '',
    closingDisclosureLastReceipt: status.trid?.closingDisclosureLastReceipt || '',
    loanAppraisalComplete: status.trid?.loanAppraisalComplete || '',
    earliestClosing: status.trid?.earliestClosing || '',
  },
  skippedAutopay: status.skippedAutopay || '',
  hardCreditUnlockedOrUnFrozen: status.hardCreditUnlockedOrUnFrozen || '',
  hardCreditLockedOrFrozen: status.hardCreditLockedOrFrozen || '',
});

const mergeCashoutRefinance = (prevCashoutRefinance: any, cashoutRefinance: any) => ({
  ...prevCashoutRefinance,
  uuid: cashoutRefinance.uuid,
  loanNumber: cashoutRefinance.loanNumber,
  amount: cashoutRefinance.amount,
  cashoutAmount: cashoutRefinance.cashoutAmount,
  annualPercentageRate: cashoutRefinance.annualPercentageRate,
  monthlyPayment: cashoutRefinance.monthlyPayment,
  rate: cashoutRefinance.rate,
  term: cashoutRefinance.term,
  totalStatedIncome: cashoutRefinance.totalStatedIncome,
  hasCoBorrower: cashoutRefinance.hasCoBorrower,
  coBorrowerStatedIncome: cashoutRefinance.coborrowerStatedIncome,
  property: {
    ...prevCashoutRefinance.property,
    uuid: cashoutRefinance.property.uuid,
    street1: cashoutRefinance.property.street1,
    street2: cashoutRefinance.property.street2,
    city: cashoutRefinance.property.city,
    state: cashoutRefinance.property.state,
    zip: cashoutRefinance.property.zip,
    type: cashoutRefinance.property.type,
    ownership: cashoutRefinance.property.ownership,
    ownerLegalName: cashoutRefinance.property.ownerLegalName,
  },
  appStatus: cashoutRefinance.appStatus,
  borrower: cashoutRefinance.borrower.map((borrower: any) => {
    const prevBorrower = prevCashoutRefinance?.borrower?.find((b: any) => b.identityUuid === borrower.identityUuid) || {};

    return {
      ...prevBorrower,
      identityUuid: borrower.identityUuid,
      documents: mergeList(prevBorrower?.documents, borrower.documents),
      residences: borrower.residences,
      primaryEmploymentIncome: mergeList(prevBorrower?.primaryEmploymentIncome, borrower.primaryEmploymentIncome),
      secondaryEmploymentIncome: mergeList(prevBorrower?.secondaryEmploymentIncome, borrower.secondaryEmploymentIncome),
      previousEmploymentIncome: mergeList(prevBorrower?.previousEmploymentIncome, borrower.previousEmploymentIncome),
      manualPaystubs: mergeList(prevBorrower?.manualPaystubs, borrower.manualPaystubs),
      retirementIncome: {
        ...prevBorrower?.retirementIncome,
        monthlyPensionAnnuityIncome: borrower.retirementIncome?.monthlyPensionAnnuityIncome,
        i401KBalance: borrower.retirementIncome?.i401KBalance,
        iraBalance: borrower.retirementIncome?.iraBalance,
        keoghBalance: borrower.retirementIncome?.keoghBalance,
      },
      rentalIncome: mergeList(prevBorrower?.rentalIncome, borrower.rentalIncome, 'documentUuid'),
      childSupportIncome: {
        ...prevBorrower?.childSupportIncome,
        startDate: borrower.childSupportIncome?.startDate,
        dobOfDependents: borrower.childSupportIncome?.dobOfDependents,
        monthlyPayment: borrower.childSupportIncome?.monthlyPayment,
      },
      alimonyIncome: {
        ...prevBorrower?.alimonyIncome,
        startDate: borrower.alimonyIncome?.startDate,
        endDate: borrower.alimonyIncome?.endDate,
        monthlyPayment: borrower.alimonyIncome?.monthlyPayment,
      },
      additionalIncome: {
        ...prevBorrower?.additionalIncome,
        interestDividendsMonthlyAmount: borrower.additionalIncome?.interestDividendsMonthlyAmount,
        monthlySsIncome: borrower.additionalIncome?.monthlySsIncome,
        monthlyOtherIncome: borrower.additionalIncome?.monthlyOtherIncome,
        monthlyPublicAssistanceIncome: borrower.additionalIncome?.monthlyPublicAssistanceIncome,
      },
      militaryService: {
        ...prevBorrower?.militaryService,
        served: borrower.militaryService.served,
        currentlyServing: borrower.militaryService.currentlyServing,
        projectedExpirationMonth: borrower.militaryService.projectedExpirationMonth,
        projectedExpirationYear: borrower.militaryService.projectedExpirationYear,
        currentlyRetired: borrower.militaryService.currentlyRetired,
        nonActivatedMember: borrower.militaryService.nonActivatedMember,
        survivingSpouse: borrower.militaryService.survivingSpouse,
      },
      declarations: {
        ...prevBorrower?.declarations,
        occupiedAsPrimaryResidence: borrower.declarations.occupiedAsPrimaryResidence,
        undisclosedBorrowedFunds: borrower.declarations.undisclosedBorrowedFunds,
        undisclosedBorrowedFundsAmount: borrower.declarations.undisclosedBorrowedFundsAmount,
        undisclosedMortgageApplication: borrower.declarations.undisclosedMortgageApplication,
        undisclosedCreditApplication: borrower.declarations.undisclosedCreditApplication,
        propertyProposedCleanEnergyLien: borrower.declarations.propertyProposedCleanEnergyLien,
        undisclosedComakerOfNote: borrower.declarations.undisclosedComakerOfNote,
        outstandingJudgement: borrower.declarations.outstandingJudgement,
        presentlyDelinquent: borrower.declarations.presentlyDelinquent,
        partyToLawsuit: borrower.declarations.partyToLawsuit,
        priorPropertyDeedInLieuConveyed: borrower.declarations.priorPropertyDeedInLieuConveyed,
        priorPropertyShortSaleCompleted: borrower.declarations.priorPropertyShortSaleCompleted,
        priorPropertyForeclosureCompleted: borrower.declarations.priorPropertyForeclosureCompleted,
        bankruptcy: borrower.declarations.bankruptcy,
        bankruptcyChapterType: borrower.declarations.bankruptcyChapterType,
        ownershipOfProperty3Years: borrower.declarations.ownershipOfProperty3Years,
        propertyType: borrower.declarations.propertyType,
        propertyOwnership: borrower.declarations.propertyOwnership,
        citizenshipStatus: borrower.declarations.citizenshipStatus,
        otherPayments: mergeList(prevBorrower?.declarations?.otherPayments, borrower.declarations.otherPayments),
      },
      hasHmda: borrower.hasHmda,
      hasSsn: borrower.hasSsn,
      consents: mergeList(prevBorrower?.consents, borrower.consents),
      liabilities: borrower.liabilities,
      liabilitiesComplete: borrower.liabilitiesComplete,
      additionalPropertiesOwned: borrower.additionalPropertiesOwned,
      relationshipToPrimary: borrower.relationshipToPrimary,
      hasNonLegalSpouse: borrower.hasNonLegalSpouse,
      employmentGaps: borrower.employmentGaps,
      borrowerEnteredPrimaryIncomeSource: borrower.borrowerEnteredPrimaryIncomeSource,
      employedWithAdditionalIncome: borrower.employedWithAdditionalIncome,
    };
  }),
  unverifiedBorrowers: cashoutRefinance.unverifiedBorrowers,
  reason: cashoutRefinance.reason,
  status: mergeApplicationStatus(prevCashoutRefinance.status, cashoutRefinance.statusData),
  selectedLiens: {
    ...prevCashoutRefinance?.selectedLiens,
    mortgages: mergeList(prevCashoutRefinance.selectedLiens?.mortgages, cashoutRefinance.selectedLiens?.mortgages, 'position'),
  },
  hasFloodInsurance: cashoutRefinance.hasFloodInsurance,
  floodInsurancePremium: cashoutRefinance.floodInsurancePremium,
  hasHomeownersInsurance: cashoutRefinance.hasHomeownersInsurance,
  homeownersInsurancePremium: cashoutRefinance.homeownersInsurancePremium,
  documents: mergeList(prevCashoutRefinance.documents, cashoutRefinance.documents),
  payType: cashoutRefinance.payType,
  closingDate: cashoutRefinance.closingDate,
  manualClosingDetails: {
    ...prevCashoutRefinance.manualClosingDetails,
    address: {
      ...prevCashoutRefinance?.manualClosingDetails?.address,
      street1: cashoutRefinance?.manualClosingDetails?.address?.street1,
      street2: cashoutRefinance?.manualClosingDetails?.address?.street2,
      city: cashoutRefinance?.manualClosingDetails?.address?.city,
      state: cashoutRefinance?.manualClosingDetails?.address?.state,
      zip: cashoutRefinance?.manualClosingDetails?.address?.zip,
    },
    contactNumber: cashoutRefinance?.manualClosingDetails?.contactNumber,
  },
  auditFields: {
    ...prevCashoutRefinance.auditFields,
    createdDate: cashoutRefinance.auditFields.createdDate,
    createdBy: cashoutRefinance.auditFields.createdBy,
    updatedDate: cashoutRefinance.auditFields.updatedDate,
    updatedBy: cashoutRefinance.auditFields.updatedBy,
    message: cashoutRefinance.auditFields.message,
  },
  signers: cashoutRefinance.signers,
  titleClearPayoff: cashoutRefinance.titleClearPayoff,
  appraisalValue: cashoutRefinance.appraisalValue,
  loanType: cashoutRefinance.loanType,
  appStartDate: cashoutRefinance.appStartDate,
  rateLockDetails: {
    ...prevCashoutRefinance.rateLockDetails,
    rateLockStartDate: cashoutRefinance.rateLockDetails?.rateLockStartDate,
    rateLockEndDate: cashoutRefinance.rateLockDetails?.rateLockEndDate,
  },
  creditInquiries: cashoutRefinance.creditInquiries,
  inquiryDataSubmitted: cashoutRefinance.inquiryDataSubmitted,
  duAppraisalWaiver: cashoutRefinance.duAppraisalWaiver,
  creditCardCollected: cashoutRefinance.creditCardCollected,
});

const reducer = handleActions(
  {
    [`${GET_USER_CASHOUT_REFINANCES}_${REQUEST}`]: (state: any = initialState) => ({
      ...state,
      loading: true,
      error: '',
    }),
    [`${GET_USER_CASHOUT_REFINANCES}_${FAILURE}`]: (state, action) => ({
      ...state,
      loading: false,
      error: payloadError(action),
    }),
    [`${GET_USER_CASHOUT_REFINANCES}_${SUCCESS}`]: (state: CashoutRefinanceState, action) => ({
      ...state,
      cashoutRefinancesByUuid: {
        ...(action.payload as any).reduce(
          (acc: any, { cashoutRefinance: app }: any) => ({
            ...acc,
            [app.uuid]: mergeCashoutRefinance(state.cashoutRefinancesByUuid[app.uuid] || {}, app),
          }),
          state.cashoutRefinancesByUuid
        ),
      },
      loading: false,
      error: '',
    }),
    [`${GET_EXTERNAL_CONDITIONS}_${REQUEST}`]: (state: any = initialState) => ({
      ...state,
      loading: true,
      error: '',
    }),
    [`${GET_EXTERNAL_CONDITIONS}_${FAILURE}`]: (state, action) => ({
      ...state,
      loading: false,
      error: payloadError(action),
    }),
  },
  initialState
);

export default reducer;
