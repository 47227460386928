import { cssVar } from '@figure/figure-frontlib-gui';

const REGULAR_LOAN_STATUS = {
  bgColor: cssVar('colors.primary.150'),
  color: cssVar('colors.primary.500'),
  icon: 'FLAG_FILL_SMALL',
  iconColor: cssVar('colors.primary.500'),
};

const NEGATIVE_LOAN_STATUS = {
  bgColor: cssVar('colors.negative.100'),
  color: cssVar('colors.negative.450'),
  icon: 'ERROR_FILL_SMALL',
};

const POSITIVE_LOAN_STATUS = {
  bgColor: cssVar('colors.positive.100'),
  color: cssVar('colors.positive.600'),
  icon: 'CHECK_FILL_SMALL',
  iconColor: cssVar('colors.positive.450'),
};

const PENDING_LOAN_STATUS = {
  bgColor: cssVar('colors.notice.100'),
  color: cssVar('colors.notice.600'),
  icon: 'PENDING_FILL_SMALL',
  iconColor: cssVar('colors.notice.400'),
};

export const LOAN_STATUSES = {
  IN_REPAY: { copy: 'In repay', ...REGULAR_LOAN_STATUS },
  DELQ_1_9: { copy: 'Past due', ...NEGATIVE_LOAN_STATUS },
  DELQ_1_29: { copy: 'Past due', ...NEGATIVE_LOAN_STATUS },
  DELQ_10_29: { copy: 'Past due', ...NEGATIVE_LOAN_STATUS },
  DELQ_30_59: { copy: 'Past due', ...NEGATIVE_LOAN_STATUS },
  DELQ_30_60: { copy: 'Past due', ...NEGATIVE_LOAN_STATUS },
  DELQ_60_89: { copy: 'Past due', ...NEGATIVE_LOAN_STATUS },
  DELQ_61_UP: { copy: 'Past due', ...NEGATIVE_LOAN_STATUS },
  FORBEARANCE: { copy: 'Forbearance', ...NEGATIVE_LOAN_STATUS },
  BANKRUPTCY: { copy: 'Bankruptcy', ...NEGATIVE_LOAN_STATUS },
  DEFAULT: { copy: 'Default', ...REGULAR_LOAN_STATUS },
  DEFERMENT: { copy: 'Deferment', ...PENDING_LOAN_STATUS },
  PAID_CLOSED: { copy: 'Paid closed', ...POSITIVE_LOAN_STATUS },
  PAID_OPEN: { copy: 'Paid open', ...POSITIVE_LOAN_STATUS },
  CHARGEDOFF: { copy: 'Chargedoff', ...POSITIVE_LOAN_STATUS },
  PAID_CHARGEDOFF: { copy: 'Paid chargedoff', ...POSITIVE_LOAN_STATUS },
  PAID_SETTLED: { copy: 'Paid settled', ...POSITIVE_LOAN_STATUS },
  PENDING_CLOSURE: { copy: 'Pending Closure', ...PENDING_LOAN_STATUS },
  FORECLOSED: { copy: 'Foreclosed', ...NEGATIVE_LOAN_STATUS },
  TRANSFERRED: { copy: 'Transferred', ...POSITIVE_LOAN_STATUS },
  REO: { copy: 'REO', ...POSITIVE_LOAN_STATUS },
  DECLINED: { copy: 'Declined', ...NEGATIVE_LOAN_STATUS },
  DECLINED_COLLATERAL_RELEASE: { copy: 'Declined', ...NEGATIVE_LOAN_STATUS },
  CANCELLED_COLLATERAL_RELEASE: { copy: 'Cancelled', ...NEGATIVE_LOAN_STATUS },
} as { [key: string]: any };

export enum ServicingStatus {
  IN_REPAY = 'IN_REPAY',
  TRANSFERRED = 'TRANSFERRED',
  PENDING_CLOSURE = 'PENDING_CLOSURE',
}
export enum KrangStatus {
  COMPLETE = 'COMPLETE',
  SOFT_CREDIT_PULL = 'SOFT_CREDIT_PULL',
}
export enum LoanStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  TRANSFERRED = 'TRANSFERRED', // in servicing, but external servicer
  TRANSFERRED_HOMEPOINT = 'TRANSFERRED_HOMEPOINT', // in servicing, direct transfer to homepoint
  IN_SERVICING = 'IN_SERVICING', // figure servicing
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  DECLINED = 'DECLINED',
  ERROR = 'ERROR', // servicing is down
}
