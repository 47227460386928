import {
  cssVar,
  Div,
  flex,
  Spinner,
  GlobalProps,
  positionAbsolute,
  disableTabindexByElement,
  restoreDisabledTabindexByElement,
  Z_INDEX_LOADER,
} from '@figure/figure-frontlib-gui';
import { createPortal } from 'react-dom';
import { css } from 'styled-components';
import { useEffect, useRef } from 'react';

export type OverlaySpinnerProps = {
  isLoading: boolean;
  parentElementId?: string;
  message?: string;
  bgColor?: string;
} & GlobalProps;

const OverlaySpinner = ({ $css, isLoading, parentElementId, bgColor = 'rgba(228, 229, 235, 0.5)', ...rest }: OverlaySpinnerProps) => {
  const elRef = useRef<HTMLElement | null>(null);
  const parentRef = useRef<HTMLElement | null>(null);
  const el = parentElementId ? document.getElementById(parentElementId) : null;
  useEffect(() => {
    if (!parentRef.current && elRef.current) parentRef.current = el || (elRef.current as HTMLElement).parentElement;
    if (isLoading) {
      parentRef.current?.setAttribute('aria-hidden', 'true');
      disableTabindexByElement(parentRef.current as HTMLElement);
    } else {
      parentRef.current?.removeAttribute('aria-hidden');
      restoreDisabledTabindexByElement(parentRef.current);
    }
  }, [el, isLoading]);
  useEffect(
    () => () => {
      parentRef.current?.removeAttribute('aria-hidden');
      restoreDisabledTabindexByElement(parentRef.current);
    },
    []
  );
  const Comp = (
    <Div
      ref={elRef}
      $css={css`
        background: ${bgColor};
        z-index: ${Z_INDEX_LOADER - 1};
        ${positionAbsolute()};
        ${flex()};
        ${$css};
      `}
      {...rest}
    >
      <Spinner width="6.25rem" color={cssVar('colors.primary.600')} />
    </Div>
  );
  return isLoading ? (el ? createPortal(Comp, el) : Comp) : null;
};

export default OverlaySpinner;
