import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import type { AccReqSummary } from 'types/accreditation-request';
import { AxiosError } from 'axios';
import { axios, PASSPORT_API_BASE } from 'constant';

export enum PASSPORT_QUERIES {
  GET_ALL_PASSPORTS = 'GET_ALL_PASSPORTS',
  GET_ALL_ACC_REQS = 'GET_ALL_ACC_REQS',
  GET_PASSPORT_PROFILE = 'GET_PASSPORT_PROFILE',
}

export const useGetAllAccReqs = (address?: string, options?: UseQueryOptions<AccReqSummary[], AxiosError>) =>
  useQuery(
    [PASSPORT_QUERIES.GET_ALL_ACC_REQS, address] as QueryKey,

    async () => {
      const accReqs = await axios
        .get<Promise<AccReqSummary[]>>(`${PASSPORT_API_BASE}/v3/accreditation-requests/summary`)
        .then(({ data }) => data);
      return accReqs.filter((accReq) => accReq.walletId === address);
    },
    options
  );

export default {
  useGetAllAccReqs,
};
