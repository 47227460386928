import _axios from 'axios';

export const axios = _axios.create({
  baseURL: window.location.origin,
  timeout: 30000,
  headers: {
    Accept: 'application/json, application/pdf',
    'Content-Type': 'application/json',
  },
});
