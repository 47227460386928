import { flex, Copy, GlobalProps, Div, TYPOGRAPHY } from '@figure/figure-frontlib-gui';
import { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled(Div)`
  overflow: visible;
  pointer-events: none;
`;

const CopyWrapper = styled.button`
  ${flex()}
  position: relative;
  flex: 0 0 auto;
  pointer-events: auto;

  svg {
    flex: 0 0 auto;
  }
`;

type ToolTipProps = {
  active: boolean;
};

const ToolTip = styled.p<ToolTipProps>`
  pointer-events: none;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  background: #fff;
  border-radius: 4px;
  padding: 0.625rem;
  transition: opacity 300ms ease ${({ active }) => !active && '500ms'};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  opacity: ${({ active }) => (active ? 1 : 0)};
  z-index: 10;
`;

const CopyContent = ({
  text,
  label = '',
  isTruncated = false,
  ...rest
}: { text: string; label?: string; isTruncated?: boolean } & GlobalProps) => {
  const [tooltipActive, setTooltipActive] = useState(false);
  const [tooltipText, setTooltipText] = useState('Click to Copy');
  const copy = useCallback(() => {
    setTimeout(() => {
      setTooltipText('Copied to clipboard');
      setTooltipActive(true);
      const textArea = document.createElement('textarea');
      textArea.style.position = 'fixed';
      textArea.style.top = '0';
      textArea.style.right = '0';
      textArea.style.opacity = '0';
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      setTimeout(() => {
        setTooltipActive(false);
      }, 300);
    }, 300);
  }, [text]);
  return (
    <Wrapper as="div" {...rest}>
      <CopyWrapper
        onClick={copy}
        onMouseEnter={() => {
          setTooltipText('Click to Copy');
          setTooltipActive(true);
        }}
        onMouseLeave={() => setTooltipActive(false)}
      >
        <Div
          $css={css`
            word-break: break-all;
            text-align: left;
            ${TYPOGRAPHY.S_SEMIBOLD};
          `}
        >
          {label} {isTruncated ? `${text.slice(0, 3)}...${text.slice(text.length - 12, text.length)}` : text}
        </Div>
        <ToolTip active={tooltipActive}>{tooltipText}</ToolTip>
        <Copy />
      </CopyWrapper>
    </Wrapper>
  );
};

export default CopyContent;
