import { handleActions } from 'redux-actions';
import { httpActions, servicingActions } from '@figure/frontlib-onboarding-redux';
import { SERVICING_SERVICE_DOWN } from '../actions/servicing-actions';

export const initialState = {
  error: '',
  errorDescription: '',
  loading: false,
};

// TODO update onboarding redux to combine servicing status reducer and servicing
const servicingStatusReducer = handleActions(
  {
    [`${servicingActions.GET_USER_LOAN_STATUSES}_${httpActions.REQUEST}`]() {
      return {
        ...initialState,
        loading: true,
      };
    },
    [`${servicingActions.GET_USER_LOAN_STATUSES}_${httpActions.REQUEST}`]() {
      return {
        ...initialState,
        loading: false,
      };
    },
    [SERVICING_SERVICE_DOWN](state) {
      return {
        ...state,
        error: 'We are sorry for the delay.',
        errorDescription: 'Looks like one of our services is down.',
        loading: false,
      };
    },
  },
  initialState
);

export default servicingStatusReducer;
