import { GlobalProps } from '@figure/figure-frontlib-gui';
import { useLocation } from 'react-router';
import { TelemetryContextProvider as ContextProvider } from '@figure/frontlib-growth';
import { useIdentity } from 'hooks';
import { ProfileService } from 'services';

const TelemetryContextProvider = ({ children }: GlobalProps) => {
  const { identity } = useIdentity();
  const { data: profile } = ProfileService.useGetProfile({ enabled: false });
  const location = useLocation();
  return (
    <ContextProvider
      gtmContainerId={import.meta.env.VITE_GTM_CONTAINER_ID}
      location={location}
      appVersion="0.1.0"
      defaultEventType="USER"
      profile={profile}
      identity={identity}
      delay
    >
      {children}
    </ContextProvider>
  );
};

export default TelemetryContextProvider;
