import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { useScrollToTop } from 'hooks';
import { queryClient } from './constant';
import DashboardAppShell from './components/layout/Containers';
import { AccountContextProvider, ThemeContextProvider } from 'contexts';

const App = () => {
  useScrollToTop();
  return (
    <QueryClientProvider client={queryClient}>
      <AccountContextProvider>
        <ThemeContextProvider>
          <DashboardAppShell />
        </ThemeContextProvider>
      </AccountContextProvider>
    </QueryClientProvider>
  );
};
export default () => (
  <Router basename={import.meta.env.BASE_URL}>
    <App />
  </Router>
);
