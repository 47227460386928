import { FIGURE_ORIGIN, axios } from 'constant';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';

enum PROFILE_QUERIES {
  GET_PROFILE = 'GET_PROFILE',
  GET_CONSENT_TYPES = 'GET_CONSENT_TYPES',
}

enum Consent {
  TERMS = 'TERMS',
  PRIVACY = 'PRIVACY',
}

const CONTEXT = '/service-profile/api/v1/profile';

const useGetProfile = (options?: UseQueryOptions<any, AxiosError, any, any>) =>
  useQuery(
    [PROFILE_QUERIES.GET_PROFILE],
    async () => {
      const { data } = await axios.get<any>(`${FIGURE_ORIGIN}${CONTEXT}`);
      return data;
    },
    options
  );

const useGetConsentTypes = (options?: UseQueryOptions<any, AxiosError, any, any>) =>
  useQuery(
    [PROFILE_QUERIES.GET_CONSENT_TYPES],
    async () => {
      const { data } = await axios.get<any>(`${FIGURE_ORIGIN}${CONTEXT}/consent-types`);
      return data;
    },
    options
  );
const useUpdateConsents = (options?: UseMutationOptions<unknown, AxiosError, any>) =>
  useMutation((consentTypes) => {
    const consentsToUpdate = (consentTypes as any)
      ?.filter(({ type }: any) => [Consent.TERMS, Consent.PRIVACY].includes(type))
      .map(({ type, version }: any) => ({ type, version }));
    return axios.put(`${FIGURE_ORIGIN}${CONTEXT}/consent-list`, consentsToUpdate);
  }, options);

const getLatestConsent = (profileConsents: any, consentType: any) =>
  (profileConsents as any).filter((c: any) => c.type === consentType).sort((c1: any, c2: any) => c2.version - c1.version)[0] || {
    version: 0,
  };

const hasOutdatedConsents = (profileConsents: any, consentTypes: any) => {
  const userTosConsent = getLatestConsent(profileConsents, Consent.TERMS);
  const userPpConsent = getLatestConsent(profileConsents, Consent.PRIVACY);
  if (userTosConsent.version && userPpConsent.version) {
    const tosConsent = (consentTypes as any)?.find((c: any) => c.type === Consent.TERMS) || {
      version: 0,
    };
    const ppConsent = (consentTypes as any)?.find((c: any) => c.type === Consent.PRIVACY) || {
      version: 0,
    };
    const termsConsentStale = tosConsent.version > userTosConsent.version;
    const privacyConsentStale = ppConsent.version > userPpConsent.version;
    if (termsConsentStale || privacyConsentStale) {
      return true;
    }
  }
  return false;
};

export default { useGetProfile, useGetConsentTypes, hasOutdatedConsents, useUpdateConsents };
