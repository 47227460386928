import { createContext, useContext } from 'react';
import { useWalletService } from '@provenanceio/wallet-lib';
import { GlobalProps } from '@figure/figure-frontlib-gui';
import { FIGURE_ORIGIN } from 'constant';

const PWalletContext = createContext<ReturnType<typeof useWalletService> | undefined>(undefined);
const FigureWalletContext = createContext<ReturnType<typeof useWalletService> | undefined>(undefined);
const FesWalletContext = createContext<ReturnType<typeof useWalletService> | undefined>(undefined);

const WalletWindowContextProvider = ({ children }: GlobalProps) => {
  const { walletService: pWalletService, walletState: pWalletState } = useWalletService(
    import.meta.env.VITE_PROVENANCE_WALLET_URL as string
  );
  const { walletService: fesWalletService, walletState: fesWalletState } = useWalletService(import.meta.env.VITE_FES_URL as string);
  const { walletService: figureWalletService, walletState: figureWalletState } = useWalletService(`${FIGURE_ORIGIN}/dashboard`);
  return (
    <PWalletContext.Provider value={{ walletService: pWalletService, walletState: pWalletState }}>
      <FigureWalletContext.Provider value={{ walletService: figureWalletService, walletState: figureWalletState }}>
        <FesWalletContext.Provider value={{ walletService: fesWalletService, walletState: fesWalletState }}>
          {children}
        </FesWalletContext.Provider>
      </FigureWalletContext.Provider>
    </PWalletContext.Provider>
  );
};

const usePWallet = () => {
  const context = useContext(PWalletContext);
  if (context === undefined) {
    throw new Error('usePWallet must be used within a WalletWindowContextProvider');
  }
  return context;
};

const useFigureWallet = () => {
  const context = useContext(FigureWalletContext);
  if (context === undefined) {
    throw new Error('useFigureWallet must be used within a WalletWindowContextProvider');
  }
  return context;
};

const useFesWallet = () => {
  const context = useContext(FesWalletContext);
  if (context === undefined) {
    throw new Error('useFesWallet must be used within a WalletWindowContextProvider');
  }
  return context;
};

export { WalletWindowContextProvider, usePWallet, useFigureWallet, useFesWallet };
