import { useQuery, useMutation, UseQueryOptions, UseMutationOptions, QueryKey } from '@tanstack/react-query';
import { AxiosError, AxiosInstance } from 'axios';
import { axios, ERROR_MSG, FIGURE_ORIGIN } from 'constant';
import { useGetWalletByAddress } from 'features/RBAC/hooks';
import { ExchangeResponse, FundsResponse } from 'types';
import { parseFunds } from './parse-funds';

const CONTEXT = '/marketplace/secure/api/v1';

export enum MARKETPLACE_QUERIES {
  GET_FUNDS = 'GET_FUNDS',
}

function useFunds(address: string, options?: UseQueryOptions<ReturnType<typeof parseFunds>, AxiosError>) {
  const wallet = useGetWalletByAddress(address!);

  return useQuery(
    [MARKETPLACE_QUERIES.GET_FUNDS, address] as QueryKey,
    async () => {
      if (!address) throw new Error(`${ERROR_MSG} retrieving wallet info.`);

      if (wallet?.shared) {
        return parseFunds([]);
      }

      // TODO remove exchange login when we no longer use that cookie to determine current wallet
      await (axios as AxiosInstance).post<ExchangeResponse>(`${FIGURE_ORIGIN}/service-wallet/secure/api/v1/account/exchange/login`, {
        address,
      });
      const { data } = await (axios as AxiosInstance).get<FundsResponse>(`${FIGURE_ORIGIN}${CONTEXT}/buyer/funds`, {
        headers: { 'x-wallet-address': address },
        withCredentials: true, // send cookies cross domain (mostly for dev reasons)
      });
      const funds = data?.funds || [];
      return parseFunds(funds);
    },
    options
  );
}

function useRemoveInterest(options?: UseMutationOptions<unknown, AxiosError, string>) {
  return useMutation((auctionUuid) => axios.delete(`${FIGURE_ORIGIN}${CONTEXT}/buyer/auctions/${auctionUuid}/interest`), options);
}

export default {
  useRemoveInterest,
  useFunds,
};
