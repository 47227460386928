import { FundDetail, TRANSACTION_TYPE } from 'types';
import { PositionItem } from 'pages/Dashboard/Marketplace/types';
import { BID_STATUS, STATUS_COMPLETED, STATUS_READABLE } from 'pages/Dashboard/Marketplace/constants';
import { formatMoney as moneyFormat } from '@figure/frontlib-util/lib/numeric-formatters';
import { getLinks } from 'services/marketplace/links';
import { parseOpenOrder } from 'services/marketplace/open-orders';
import { format } from 'date-fns';
import { toCapitalCase } from '@figure/figure-frontlib-gui';

const IS_POSITION_TYPES = [
  TRANSACTION_TYPE.PRIMARY_RAISE,
  TRANSACTION_TYPE.OPEN_ENDED_FUND,
  TRANSACTION_TYPE.PRIVATE_REIT,
  TRANSACTION_TYPE.CAPITAL_FUNDS,
];

const INVESTMENT_PENDING_STATUS = [
  BID_STATUS.PENDING_TAX,
  BID_STATUS.PENDING_DUE_DATE,
  BID_STATUS.PENDING_SIGNATURE,
  BID_STATUS.PENDING,
  BID_STATUS.PENDING_FUNDS,
  BID_STATUS.PENDING_REVIEW,
];

const NO_VALUE = '--';

const calculateInvestmentRemaining = (commitment: number, amountInvested: number): string => {
  let result = NO_VALUE;
  if (commitment) {
    result = amountInvested ? `${moneyFormat(commitment - amountInvested, 2)}` : `${moneyFormat(commitment, 2)}`;
  }
  return result;
};

const INVESTMENT_LAYOUT: Array<PositionItem> = [
  {
    title: 'Name',
    value: ({ title }: FundDetail) => title,
  },
  {
    title: 'Amount Invested',
    value: ({ amountInvested, type }: FundDetail) =>
      amountInvested && amountInvested > 0 ? moneyFormat(amountInvested, 2) : type === TRANSACTION_TYPE.CAPITAL_FUNDS ? '$0' : '--',
  },
  {
    title: 'Remaining Commitment',
    render: ({ type, originalCommitment, acceptedCommitment, transactions }: FundDetail) =>
      type === TRANSACTION_TYPE.CAPITAL_FUNDS &&
      !!((originalCommitment || acceptedCommitment) && ![BID_STATUS.PENDING_REVIEW].includes(transactions[0].status)),
    value: ({ amountInvested, originalCommitment, acceptedCommitment }: FundDetail) =>
      acceptedCommitment
        ? calculateInvestmentRemaining(acceptedCommitment, amountInvested)
        : calculateInvestmentRemaining(originalCommitment, amountInvested),
  },
];

type LayoutItem = {
  title: string;
  value: string;
};

const getTitlesString = (data: LayoutItem[]) =>
  data.reduce((titleString, { title }) => {
    return `${titleString}${title}`;
  }, '');

const parseLayout = (fund: FundDetail, layout: PositionItem[]) => {
  const parsedLayout = layout.reduce<LayoutItem[]>((parsedLayout, { render, value, title }) => {
    if (render && !render(fund)) {
      return parsedLayout;
    }
    return [
      ...parsedLayout,
      {
        title,
        value: value(fund),
      },
    ];
  }, []);
  return {
    titleString: getTitlesString(parsedLayout),
    layout: parsedLayout,
  };
};

export type Position = {
  layout: LayoutItem[];
  links: ReturnType<typeof getLinks>;
};

export type ParsedFunds = {
  positions: { [key: string]: Position[] };
  marketplace: { [key: string]: Position[] };
  openOrders: ReturnType<typeof parseOpenOrder>[];
  rawData: FundDetail[];
};

export function parseFunds(funds: FundDetail[]): ParsedFunds {
  const data: ParsedFunds = {
    positions: {},
    marketplace: {},
    openOrders: [],
    rawData: funds,
  };
  funds.forEach((fund) => {
    const { type, transactions, status, date, title, uuid } = fund;
    if (IS_POSITION_TYPES.includes(type)) {
      const hasInvestment =
        IS_POSITION_TYPES.includes(type) &&
        transactions.length > 0 &&
        !(transactions.length === 1 && INVESTMENT_PENDING_STATUS.includes(transactions[0].status));
      if (hasInvestment) {
        const { titleString, layout } = parseLayout(fund, INVESTMENT_LAYOUT);
        if (!data.positions[titleString]) data.positions[titleString] = [];
        data.positions[titleString].push({ layout, links: getLinks(status, fund) });
      } else if (transactions.length === 0 && status === BID_STATUS.INTERESTED) {
        data.openOrders.push({
          order: {
            uuid,
            type: NO_VALUE,
            date: format(new Date(date), 'MM/dd/yyyy'),
            amount: NO_VALUE,
            quantity: NO_VALUE,
            status: toCapitalCase(STATUS_READABLE[status.toUpperCase()] || ''),
            asset: title,
          },
          links: getLinks(status, fund),
        });
      }
      const openOrders = transactions
        .filter(({ status }) => !STATUS_COMPLETED.includes(status))
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      data.openOrders.push(
        ...openOrders.reduce<ReturnType<typeof parseOpenOrder>[]>((orders, order) => [...orders, parseOpenOrder(fund, order)], [])
      );
    } else if (type === TRANSACTION_TYPE.SECONDARY_MARKET && ![BID_STATUS.APPROVED].includes(fund.status.toUpperCase())) {
      data.openOrders.push({
        order: {
          uuid,
          type: 'Secondary Participation',
          date: format(new Date(fund.date), 'MM/dd/yyyy'),
          amount: NO_VALUE,
          quantity: NO_VALUE,
          status: toCapitalCase(status || ''),
          asset: fund.title,
        },
        links: getLinks(status, fund),
      });
    }
  });
  return data;
}
