import { Div, InterpolationWithProps } from '@figure/figure-frontlib-gui';
import { css, FlattenSimpleInterpolation } from 'styled-components';
import OverlaySpinner, { OverlaySpinnerProps } from './OverlaySpinner';

const OverlaySpinnerContainer = ({
  children,
  isLoading,
  message,
  spinnerCss,
  bgColor,
  positionRelativeOnParent = false,
  $css,
  ...rest
}: Omit<OverlaySpinnerProps, 'parentEl'> & {
  spinnerCss?: FlattenSimpleInterpolation | InterpolationWithProps;
  positionRelativeOnParent?: boolean;
}) => {
  return (
    <Div
      $css={css`
        position: ${positionRelativeOnParent ? 'unset' : 'relative'};
        ${$css}
      `}
      {...rest}
    >
      <OverlaySpinner isLoading={isLoading} message={message} $css={spinnerCss} bgColor={bgColor} />
      {children}
    </Div>
  );
};

export default OverlaySpinnerContainer;
