export enum ProductType {
  MORTGAGE_JUMBO = 'MORTGAGE_JUMBO',
  MORTGAGE_CONVENTIONAL = 'MORTGAGE_CONVENTIONAL',
  MORTGAGE_CASHOUT_REFI = 'MORTGAGE_CASHOUT_REFI',
  MORTGAGE_HIGHBALANCE = 'MORTGAGE_HIGHBALANCE',
  MORTGAGE_ARM = 'MORTGAGE_ARM',
  MERLOC = 'MERLOC',
  FIGURE_HELOC = 'FIGURE_HELOC',
  PIGGYBACK_HELOC = 'PIGGYBACK_HELOC',
  HELOAN = 'HELOAN',
  CRYPTO_HELOC = 'CRYPTO_HELOC',
  STUDENT_LOAN_REFI = 'STUDENT_LOAN_REFI',
  STUDENT_LOAN_PARENT_REFI = 'STUDENT_LOAN_PARENT_REFI',
  PERSONAL_LOAN = 'PERSONAL_LOAN',
  CRYPTO_PERSONAL_LOAN = 'CRYPTO_PERSONAL_LOAN',
  FIGURE_BANK_CREDIT = 'FIGURE_BANK_CREDIT',
}

export enum ProductCategory {
  HELOAN = 'HELOAN',
  MORTGAGE = 'MORTGAGE',
  HELOC = 'HELOC',
  CRYPTO_HELOC = 'CRYPTO_HELOC',
  MERLOC = 'MERLOC',
  SLR = 'SLR',
  PL = 'PL',
  CRYPTO_PL = 'CRYPTO_PL',
  FBC = 'FBC',
}

export enum ApplicationStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  FUNDED = 'FUNDED',
  DECLINED = 'DECLINED',
  CANCELLED = 'CANCELLED',
  IN_SERVICING = 'IN_SERVICING',
  ERROR = 'ERROR',
}
