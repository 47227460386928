import { css } from 'styled-components';
import { cssVar, Div, H5, Modal, P, flex } from '@figure/figure-frontlib-gui';

export const ID = 'SUBMITTING';

const SubmittingModal = ({ title = 'Submitting' }: { title?: string }) => {
  return (
    <Modal
      title={title}
      useDefaultCloseButton={false}
      shouldCloseOnOutsideClick={false}
      modalId={ID}
      aria-label={title}
      wrapperCss={css`
        .modal-header {
          display: none;
        }
      `}
    >
      <Div $css={flex('center')}>
        <img
          src="https://www.datocms-assets.com/33246/1659998579-figure_loading_4.gif"
          alt="loading animation"
          height="120"
          width="120"
        />
      </Div>
      <H5
        $css={css`
          text-align: center;
          margin-bottom: ${cssVar('spacings.xs')};
        `}
      >
        {title}...
      </H5>
      <P
        $css={css`
          text-align: center;
        `}
      >
        Please do not close this window.
      </P>
    </Modal>
  );
};

export default SubmittingModal;
