export const BID_STATUS = {
  APPROVED: 'APPROVED',
  INTERESTED: 'INTERESTED',
  PENDING_TAX: 'PENDING_TAX',
  PENDING_SIGNATURE: 'PENDING_SIGNATURE',
  PENDING_REVIEW: 'PENDING_REVIEW',
  PENDING_FUNDS: 'PENDING_FUNDS',
  PENDING_DUE_DATE: 'PENDING_DUE_DATE',
  PENDING: 'PENDING',
  SUBSCRIBED: 'SUBSCRIBED',
  CONFIRM_TRANSACTION: 'CONFIRM_TRANSACTION',
  CANCELLED: 'CANCELLED',
  DECLINED: 'DECLINED',
  COMPLETE: 'COMPLETE',
  FUNDED: 'FUNDED',
  ENDED: 'ENDED',
  ACTIVE: 'ACTIVE',
  SUBMITTED: 'SUBMITTED',
  AWARDED: 'AWARDED',
  AWAITING_APPROVAL: 'AWAITING APPROVAL',
};

export const STATUS_COMPLETED = [BID_STATUS.FUNDED, BID_STATUS.COMPLETE, BID_STATUS.ENDED, BID_STATUS.CANCELLED];

export const STATUS_READABLE = {
  [BID_STATUS.APPROVED]: 'Subscription Approved',
  [BID_STATUS.INTERESTED]: 'Interest Indicated',
  [BID_STATUS.PENDING_TAX]: 'Awaiting Tax Documents',
  [BID_STATUS.PENDING_SIGNATURE]: 'Pending Signature',
  [BID_STATUS.PENDING_DUE_DATE]: 'Capital Received',
  [BID_STATUS.PENDING_REVIEW]: 'Pending Review',
  [BID_STATUS.PENDING_FUNDS]: 'Awaiting Funds',
  [BID_STATUS.PENDING]: 'Pending',
  [BID_STATUS.SUBSCRIBED]: 'Subscribed',
  [BID_STATUS.CANCELLED]: 'Canceled',
  [BID_STATUS.DECLINED]: 'Declined',
  [BID_STATUS.COMPLETE]: 'Funded',
  [BID_STATUS.CONFIRM_TRANSACTION]: 'Capital Received',
  [BID_STATUS.FUNDED]: 'Funded',
  [BID_STATUS.ENDED]: 'Ended',
  [BID_STATUS.ACTIVE]: 'Active',
  [BID_STATUS.SUBMITTED]: 'Submitted',
  [BID_STATUS.AWARDED]: 'Awarded',
};

export const FUND_READABLE: { [key: string]: string } = {
  SUBSCRIPTION_REQUEST: 'Subscription',
  REIT_SHARES: 'REIT Shares',
  CAPITAL_CALL: 'Capital Call',
  INVESTMENT: 'Investment',
};
