import { memo } from 'react';
import { cssVar, Div, media, useNavContext, LinkOrButton } from '@figure/figure-frontlib-gui';
import { css } from 'styled-components';
import { usePrivateLabelContent } from '@figure/frontlib-private-label-gui';

const MemoLogo = () => {
  // Note - sideNavOpen is only for TABLET side nav, NOT mobile
  const { sideNavOpen } = useNavContext();
  const { logoImage, logoUrl } = usePrivateLabelContent();
  return (
    <Div
      $css={css`
        width: calc(100% - (${cssVar('spacings.s')} * 2));
        ${media.MD`
          width: 100%;
          margin-left: ${sideNavOpen ? cssVar('spacings.s') : 0};
        `}
        ${media.XL`
          width: unset;
          display: inline-block;
          margin-left: unset;
          margin: 3.625rem 0 9.25rem ${cssVar('spacings.s')} ;
        `}
      `}
    >
      <Div
        $css={css`
          img {
            width: 100%;
            max-width: 152px;
          }
          margin: 20px 0;
          ${media.MD`
            display: inline-block;
          `}
        `}
      >
        <LinkOrButton sameTab href={logoUrl.startsWith('https://') ? logoUrl : `https://${logoUrl}`}>
          {logoImage()}
        </LinkOrButton>
      </Div>
    </Div>
  );
};
export default memo(MemoLogo);
