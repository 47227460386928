import { ProductCategory, ProductType } from 'services/lending-engine';

const ProductCategoryLabels: Record<ProductCategory, string> = {
  [ProductCategory.MORTGAGE]: 'Mortgage',
  [ProductCategory.HELOC]: 'Home Equity Line',
  [ProductCategory.CRYPTO_HELOC]: 'Crypto Mortgage Plus',
  [ProductCategory.HELOAN]: 'Home Equity Loan',
  [ProductCategory.MERLOC]: 'Merchant HELOC',
  [ProductCategory.SLR]: 'Student Loan',
  [ProductCategory.PL]: 'Personal Loan',
  [ProductCategory.CRYPTO_PL]: 'Crypto-Backed Loan',
  [ProductCategory.FBC]: 'Pay Loan',
};

const ProductLabelMap: Record<ProductType, string> = {
  [ProductType.MORTGAGE_JUMBO]: ProductCategoryLabels[ProductCategory.MORTGAGE],
  [ProductType.MORTGAGE_CONVENTIONAL]: ProductCategoryLabels[ProductCategory.MORTGAGE],
  [ProductType.MORTGAGE_CASHOUT_REFI]: ProductCategoryLabels[ProductCategory.MORTGAGE],
  [ProductType.MORTGAGE_HIGHBALANCE]: ProductCategoryLabels[ProductCategory.MORTGAGE],
  [ProductType.MORTGAGE_ARM]: ProductCategoryLabels[ProductCategory.MORTGAGE],
  [ProductType.MERLOC]: ProductCategoryLabels[ProductCategory.MERLOC],
  [ProductType.FIGURE_HELOC]: ProductCategoryLabels[ProductCategory.HELOC],
  [ProductType.PIGGYBACK_HELOC]: ProductCategoryLabels[ProductCategory.HELOC],
  [ProductType.HELOAN]: ProductCategoryLabels[ProductCategory.HELOAN],
  [ProductType.CRYPTO_HELOC]: ProductCategoryLabels[ProductCategory.CRYPTO_HELOC],
  [ProductType.STUDENT_LOAN_REFI]: ProductCategoryLabels[ProductCategory.SLR],
  [ProductType.STUDENT_LOAN_PARENT_REFI]: ProductCategoryLabels[ProductCategory.SLR],
  [ProductType.PERSONAL_LOAN]: ProductCategoryLabels[ProductCategory.PL],
  [ProductType.CRYPTO_PERSONAL_LOAN]: ProductCategoryLabels[ProductCategory.CRYPTO_PL],
  [ProductType.FIGURE_BANK_CREDIT]: ProductCategoryLabels[ProductCategory.FBC],
};

export const getProductTypeLabel = (productType: ProductType, fallback = 'Loan'): string =>
  productType ? ProductLabelMap[productType] || fallback : fallback;
