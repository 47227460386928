import _ from 'lodash';

const APP_STATUSES_NOT_ALLOWED = ['CANCELLED', 'DECLINED', 'EXPIRED', 'INELIGIBLE', 'UNRECOGNIZED'];

const getAllowedStatuses = (list: string[]) => _.without(list, ...APP_STATUSES_NOT_ALLOWED);

const CRYPTO_LOAN_STATUS = [
  'UNINITIALIZED_STATUS',
  'APP_START',
  'OFFER_SELECT',
  'ATTACH_PROFILE',
  'BORROWER_DETAILS',
  'KYC_VERIFY',
  'APP_TASKS',
  'SIGN_DOCS',
  'WAITING_FOR_COLLATERAL',
  'FUNDABLE',
  'EXPIRED',
  'COMPLETE',
  'DECLINED',
  'DECLINED_COLLATERAL_RELEASE',
  'CANCELLED',
  'CANCELLED_COLLATERAL_RELEASE',
  'UNRECOGNIZED',
];

const PL_APP_STATUS = [
  'APP_START',
  'NO_SOFT_CREDIT',
  'TRADELINE_SELECT', // deprecated
  'WAITING_FOR_UW',
  'PRODUCT_SELECT',
  'ADDL_INFO_SSN',
  'GEN_APP_SUMMARY',
  'APP_SUMMARY',
  'IDENT_VERIFY',
  'KYC_VERIFY',
  'INCOME_VERIFY',
  'SSN_CONSENT', // deprecated
  'HARD_CREDIT',
  'GEN_NOTE_SIGNING',
  'NOTE_SIGNING',
  'APP_REVIEW',
  'CSA_REVIEW',
  'CSA_CHECKLIST_COMPLETE',
  'AUTOPAY', // deprecated
  'FUNDABLE',
  'COMPLETE',
  'CANCELLED',
  'DECLINED',
  'UNRECOGNIZED',
];

const SLR_APP_STATUS = [
  'UNINITIALIZED_STATUS',

  // APPLYING
  'INITIAL',
  'APP_START',
  'NO_SOFT_CREDIT',
  'TRADELINE_SELECT',
  'WAITING_FOR_UW',
  'PRODUCT_SELECT',
  'ADDL_INFO_SSN',
  'IDENT_VERIFY',
  'LOAN_INFO',
  'LOAN_SELECT',
  'INCOME_VERIFY',
  'SSN_CONSENT',
  'HARD_CREDIT',
  'EDU_VERIFY',
  'APP_REVIEW',

  // PROCESSING
  'GEN_NOTE_SIGNING',
  'NOTE_SIGNING',
  'AUTOPAY',
  'GEN_RESCISSION_SIGNING',
  'RESCISSION_SIGNING',

  // ORIGINATING
  'GEN_RESCISSION',
  'RESCISSION',
  'FUNDABLE',

  // FINAL
  'COMPLETE',
  'CANCELLED',
  'DECLINED',
];

// service-application (Checklist.class from stream-data)
const HELOC_APP_STATUS = [
  'IN_PROGRESS',
  'GEN_NOTE_SIGNING',
  'NOTE_SIGNING',
  'GEN_DEED_SIGNING',
  'DEED_SIGNING',
  'TRUST_AND_INSURANCE',
  'GEN_RESCISSION',
  'RESCISSION',
  'FUNDING_DECISION',
  'RECORDING',
  'FUNDABLE',
  'COMPLETE',
  'CANCELLED',
  'DECLINED',
  'INELIGIBLE',
];

// From stream-data CRMStaus. Used for Mortgage Refinance v1 (Cashout Refi)
const MORTGAGE_REFINANCE_STATUSES = [
  'UNINITIALIZED_STATUS',
  'INITIAL',
  'LIEN_SELECT', // deprecated
  'PRODUCT_SELECT',
  'DOC_VERIFY', // deprecated
  'INCOME_VERIFY', // deprecated
  'EMPLOYMENT_VERIFY', // deprecated
  'COBORROWER_VERIFY',
  'INSURANCE',
  'DECLARATIONS',
  'PREVIOUS_RESIDENCES', // deprecated
  'REAL_ESTATE_OWNED', // deprecated
  'HMDA',
  'INITIAL_APP_APPROVE', // deprecated
  'LOAN_ESTIMATE',
  'CONFIRM_LIABILITIES',
  'SOFT_CREDIT_LOCKED', // deprecated
  'SOFT_CREDIT_FROZEN', // deprecated
  'SOFT_CREDIT_NO_MATCH', // deprecated
  'SOFT_CREDIT_PULL',
  'SOFT_CREDIT_MATCHED',
  'MATCH_LIENS',
  'PROVIDE_INCOME_INFORMATION',
  'HARD_CREDIT_PULL',
  'INITIATE_RATE_LOCK',
  'PAYSTUB_W2_UPLOAD',
  'ESTABLISH_ECOA_APP_START',
  'ECOA_APP_START_ESTABLISHED',
  'CONFIRM_CURRENT_RESIDENCE',
  'CONFIRM_ADDITIONAL_PROPERTY',
  'APPLICATION_DOCUMENTATION_NEEDED',
  'PROVIDE_MILITARY_CITIZENSHIP_INFORMATION',
  'PROVIDE_ADDITIONAL_SIGNER_INFORMATION',
  'WAITING_FOR_INITIAL_DU_UW',
  'APPLICATION_DOCUMENTATION_OBTAINED',
  'TRIGGER_APPRAISAL',
  'INITIAL_DOCS',
  'INITIAL_DOCS_SIGNED',
  'IDENT_VERIFY',
  'APPRAISAL_COMPLETE',
  'EMPLOYMENT_COMPLETE',
  'ESTABLISH_APP_COMPLETE',
  'SSN_COMPLETE', // deprecated
  'INCOME_4506T_COMPLETE',
  'TITLE_CHECK_COMPLETE',
  'VERIFICATION_OF_EMPLOYMENT_COMPLETE', // deprecated
  'PROCESSING_APP', // deprecated
  'PROCESSING_COMPLETE', // deprecated
  'PROCESSING_APPRAISAL',
  'PROCESSING_EMPLOYMENT',
  'PROCESSING_SSN',
  'PROCESSING_INCOME_4506T',
  'PROCESSING_TITLE_CHECK',
  'PROCESSING_VERIFICATION_OF_EMPLOYMENT', // deprecated
  'LINKED_ACCOUNTS', // deprecated
  'CLOSING_DISCLOSURE',
  'SCHEDULE_CLOSING',
  'PROCESSING_FINAL_DU_UNDERWRITE',
  'SIGN_CLOSING_DOCS',
  'MEMORIALIZE_CLOSING', // deprecated
  'CLOSING_COMPLETE', // deprecated
  'PICK_CLOSING_DATE', // deprecated
  'OFFER_RESELECT', // deprecated
  'FINAL_UNDERWRITING',
  'WAITING_FOR_FINAL_UNDERWRITING',
  'RESCISSION',
  'CLEAR_TO_FUND',
  'FUNDED',
  'AWAIT_CLEAR_TO_FUND',
  'FNMA_LOAN_DELIVERY',
  'COMPLETE',
  'DECLINED',
  'CANCELLED',
  'UNRECOGNIZED',
];

// service-mortgage
const MORTGAGE_REFINANCE_V2_STATUSES = [
  'UNINITIALIZED_STATE',
  'COLLECT_INITIAL_BORROWER_INFORMATION',
  'COLLECT_SOFT_CREDIT',
  'COLLECT_FRAUD_REPORT',
  'RETRIEVE_LIEN_INFO',
  'COLLECT_MANUAL_LIEN_INFO',
  'COLLECT_FEES_ESTIMATE',
  'RETRIEVE_INITIAL_OFFERS',
  'OFFER_SELECT',
  'COLLECT_1003_INFO',
  'COLLECT_HARD_CREDIT_INFO',
  'HARD_CREDIT_PULL',
  'RETRIEVE_INITIAL_DU_UNDERWRITE',
  'INITIAL_DOCUMENTS',
  'RETRIEVE_INITIAL_DOCUMENTS',
  'SIGN_INITIAL_DOCUMENTS',
  'COLLECT_1003_INFO_COMPLETE',
  'PURCHASE_AGREEMENT_REVIEW',
  'RATE_LOCK',
  'CUSTOMER_ACTIONS',
  'OFFER_RESELECT',
  'IN_PROCESSING',
  'WAITING_FOR_FINAL_UNDERWRITING',
  'FINAL_UNDERWRITING',
  'CLOSING_DISCLOSURE',
  'COLLECT_NOTARY_APPOINTMENT',
  'PROCESS_FINAL_DU_UNDERWRITE',
  'RETRIEVE_CLOSING_DOCUMENTS',
  'SIGN_CLOSING_DOCUMENTS',
  'RESCISSION',
  'AWAIT_CLEAR_TO_FUND',
  'IN_CLOSING',
  'IN_ORIGINATION',
  'CLEAR_TO_FUND',
  'IN_REVIEW',
  'EXPIRED',
  'CANCELLED',
  'DECLINED',
  'COMPLETE',
];

const PURCHASE_APP_STATUS = [
  'UNINITIALIZED_STATE',
  'COLLECT_PROPERTY_INFO',
  'PRODUCT_SELECT',
  'APP_INFO_TASKS',
  'HARD_CREDIT_PULL',
  'INITIAL_DOCS',
  'APP_INFO_TASKS_COMPLETE',
  'PURCHASE_AGREEMENT_REVIEW',
  'RATE_LOCK',
  'CUSTOMER_ACTIONS',
  'IN_PROCESSING',
  'IN_CLOSING',
  'IN_ORIGINATION',
  'CLEAR_TO_FUND',
  'EXPIRED',
  'CANCELLED',
  'DECLINED',
  'COMPLETE',
  'UNRECOGNIZED',
];

const PIGGYBACK_APP_STATUS = [
  'UNINITIALIZED_STATUS',
  'APP_START',
  'HMDA',
  'CONFIRM_OFFER',
  'BANK_INFO',
  'GEN_DOCS',
  'DISCLOSURE_REVIEW',
  'PENDING_REVIEW',
  'REVIEWED_NO_CHANGE',
  'REVIEWED_UPDATE',
  'RECONFIRM_OFFER',
  'CLOSING',
  'FUNDING_INSTRUCTIONS',
  'FUNDABLE',
  'RECORDING',
  'EXPIRED',
  'COMPLETE',
  'DECLINED',
  'CANCELLED',
];

export const ALLOWED_APP_STATUSES = {
  CRYPTO_LOAN: getAllowedStatuses(CRYPTO_LOAN_STATUS),
  PL: getAllowedStatuses(PL_APP_STATUS),
  SLR: getAllowedStatuses(SLR_APP_STATUS),
  HELOC: getAllowedStatuses(HELOC_APP_STATUS),
  MORTGAGE_REFINANCE: getAllowedStatuses(MORTGAGE_REFINANCE_STATUSES),
  MORTGAGE_REFINANCE_V2: getAllowedStatuses(MORTGAGE_REFINANCE_V2_STATUSES),
  PURCHASE: getAllowedStatuses(PURCHASE_APP_STATUS),
  PIGGYBACK: getAllowedStatuses(PIGGYBACK_APP_STATUS),
};
