import { useNavigate, useLocation } from 'react-router-dom';
import { css } from 'styled-components';
import {
  NavCombinedContainer,
  DashboardIcon,
  MarketplaceIcon,
  LogoutIcon,
  LendingIcon,
  BlogIcon,
  SupportIcon,
  GearIcon,
  cssVar,
  media,
  PassportIcon,
  DashboardNavContextProvider,
} from '@figure/figure-frontlib-gui';
import { useIdentity } from 'hooks';
import { FIGURE_ORGANIZATION, FIGURE_ORIGIN } from 'constant';
import { IdentityService } from 'services';
import { NotificationHeader } from '@figure/frontend-navi';
import { useMemo } from 'react';
import { logOutRBAC } from 'features/RBAC/service';
import { usePMAccessCheck } from 'hooks/usePMRoute';
import { Logo } from '../../gui';
import { isFigure } from '@figure/frontlib-private-label-gui';

const Notifications = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const notificationZIndex = 999;
  const excludeNotifications = ['/notifications'];
  return !excludeNotifications.includes(pathname) ? (
    <NotificationHeader
      viewAllProps={{
        onClick: () => navigate('/notifications'),
      }}
      $panelCss={css`
        ${css`
          right: -4rem;
          z-index: ${notificationZIndex};
          h2 {
            font-size: ${cssVar('typography.fontSize.s')};
            font-weight: ${cssVar('typography.fontWeight.bold')};
          }
          ${media.MD`
            right: 0;
          `}
        `}
      `}
      $css={css`
        margin-left: auto;
        margin-right: ${cssVar('spacings.xs')};
        ${media.XL`
          display: none;
        `}
        // TODO - @dmartin we shouldn't have to apply this styling here. More investigation is needed
        li {
          padding: ${cssVar('spacings.s')} 1.625rem ${cssVar('spacings.s')};
        }
      `}
    />
  ) : null;
};

const Nav = () => {
  const { organization } = useIdentity();
  const checkPerm = usePMAccessCheck();

  const { logout, clearStorage } = IdentityService;

  return useMemo(() => {
    const defaultNavConfig = [
      {
        navItems: [
          {
            display: 'Dashboard',
            linkProps: {
              to: '/',
            },
            icon: <DashboardIcon width="1.375rem" />,
            activeCheck: (pathname: string) => ['/', import.meta.env.BASE_URL].includes(pathname),
          },
          {
            display: 'Lending',
            linkProps: {
              to: '/lending',
            },
            icon: <LendingIcon width="1.375rem" />,
            activeCheck: (pathname: string) => ['/lending', `${import.meta.env.BASE_URL}/lending`].includes(pathname),
            divide: 'after',
          },
        ],
      },
      {
        navItems: [
          {
            display: 'Settings',
            linkProps: {
              to: `/settings`,
            },
            icon: <GearIcon width="1.375rem" />,
            activeCheck: (pathname: string) => pathname.includes('/settings') && !pathname.includes('/wallet'),
          },
          {
            display: 'Log out',
            linkProps: {
              onClick: () => {
                clearStorage();
                window.location.href = `/account/login`;
              },
            },
            icon: <LogoutIcon width="1.375rem" />,
          },
        ],
      },
    ];
    const alternateNavConfig: any = {
      [FIGURE_ORGANIZATION]: [
        {
          navItems: [
            {
              display: 'Dashboard',
              linkProps: {
                to: '/',
              },
              icon: <DashboardIcon width="1.375rem" />,
              activeCheck: (pathname: string) => ['/', `${import.meta.env.BASE_URL}`].includes(pathname),
            },
            {
              display: 'Lending',
              linkProps: {
                to: '/lending',
              },
              icon: <LendingIcon width="1.375rem" />,
              activeCheck: (pathname: string) => ['/lending', `${import.meta.env.BASE_URL}/lending`].includes(pathname),
            },
            {
              id: 'marketplace',
              display: 'Marketplace',
              linkProps: {
                sameTab: true,
                href: `${FIGURE_ORIGIN}/marketplace`,
              },
              icon: <MarketplaceIcon width="1.375rem" />,
              divide: checkPerm ? null : 'after',
            },
            checkPerm && {
              id: 'portfolioManager',
              display: 'Portfolio Manager',
              linkProps: {
                sameTab: true,
                href: `${FIGURE_ORIGIN}/portfolio`,
              },
              icon: <PassportIcon width="1.375rem" />,
              divide: 'after',
            },
          ].filter(Boolean),
        },
        {
          navItems: [
            {
              display: 'Blog',
              linkProps: {
                href: `${FIGURE_ORIGIN}/blog`,
                sameTab: true,
              },
              icon: <BlogIcon width="1.375rem" />,
            },
            {
              display: 'Support',
              linkProps: {
                href: `${FIGURE_ORIGIN}/contact`,
                sameTab: true,
              },
              icon: <SupportIcon width="1.375rem" />,
            },
            {
              display: 'Settings',
              linkProps: {
                to: `/settings`,
              },
              icon: <GearIcon width="1.375rem" />,
              activeCheck: (pathname: string) => pathname.includes('/settings') && !pathname.includes('/wallet'),
            },
            {
              display: 'Log out',
              linkProps: {
                onClick: async () => {
                  await logOutRBAC();
                  logout();
                  window.location.href = `${FIGURE_ORIGIN}/login`;
                },
              },
              icon: <LogoutIcon width="1.375rem" />,
            },
          ],
        },
      ],
    };
    return organization ? (
      <DashboardNavContextProvider>
        <NavCombinedContainer
          dashboardNavCss={css`
            width: 13.5rem;
          `}
          navConfig={alternateNavConfig[organization] ?? defaultNavConfig}
          logo={!isFigure ? <Logo /> : undefined}
          additionalNavBarComponent={isFigure ? <Notifications /> : undefined}
        />
      </DashboardNavContextProvider>
    ) : null;
  }, [clearStorage, checkPerm, organization, isFigure]);
};

export default Nav;
