import { FIGURE_ORIGIN } from 'constant';
import { ajaxGet } from '@figure/frontlib-onboarding-redux';

export const GET_CRYPTO_LOAN = 'CRYPTO_LOAN::GET_CRYPTO_LOANS';
export const GET_SERVICE_MARGIN_CALLS = 'CRYPTO_LOAN::SERVICE_MARGIN_CALLS;';
export const GET_CRYPTO_LOAN_AAL = 'CRYPTO_LOAN::GET_CRYPTO_LOAN_AAL';
export const DOWNLOAD_CRYPTO_LOAN_AAL = 'CRYPTO_LOAN::DOWNLOAD_CRYPTO_LOAN_AAL';

const CRYPTO_LOAN_CONTEXT = '/service-application-cryptopl/secure/api/v1';
const SECURE = `${FIGURE_ORIGIN}${CRYPTO_LOAN_CONTEXT}/applications`;
const CRYPTO_LOAN_APPLICATIONS = `${FIGURE_ORIGIN}${CRYPTO_LOAN_CONTEXT}/fetch-user/apps`;
const CRYPTO_LOAN_SERVICE_MARGIN_CALLS = '/service-margin-call/secure/borrower/calls';

export const getAllCryptoLoanApplications = () => async (dispatch: any) =>
  ajaxGet(GET_CRYPTO_LOAN, dispatch, CRYPTO_LOAN_APPLICATIONS);

export const getCryptoLoanServiceMarginCall = (appUuid: string) => async (dispatch: any) =>
  ajaxGet(GET_SERVICE_MARGIN_CALLS, dispatch, `${FIGURE_ORIGIN}${CRYPTO_LOAN_SERVICE_MARGIN_CALLS}/${appUuid}`);

export const getCryptoLoanAal = (appUuid: string) => async (dispatch: any) =>
  ajaxGet(GET_CRYPTO_LOAN_AAL, dispatch, `${SECURE}/${appUuid}/adverse-actions`, null, { appUuid });

export const downloadCryptoLoanAal = (appUuid: string, identityUuid: string, docUuid: string) => async (dispatch: any) =>
  ajaxGet(DOWNLOAD_CRYPTO_LOAN_AAL, dispatch, `${SECURE}/${appUuid}/borrowers/${identityUuid}/download-document/${docUuid}`, {
    responseType: 'arraybuffer',
  });

export default {
  getAllCryptoLoanApplications,
  getCryptoLoanServiceMarginCall,
  getCryptoLoanAal,
  downloadCryptoLoanAal,
};
