import { FIGURE_ORIGIN } from 'constant';
import { ajaxGet } from '@figure/frontlib-onboarding-redux';

const SLR_APPLICATION_CONTEXT = '/app-slr/secure/api/v1/applications';

const SLR = 'SLR';
export const GET_STUDENT_LOAN_REFINANCES = `${SLR}::GET_APPLICATIONS`;
export const GET_STUDENT_LOAN_AAL = `${SLR}::GET_AAL`;
export const DOWNLOAD_STUDENT_LOAN_AAL = `${SLR}::DOWNLOAD_DOCUMENT`;

/**
 * Get all SLR applications for user.
 */
export const getApplications = () => async (dispatch: any) =>
  ajaxGet(GET_STUDENT_LOAN_REFINANCES, dispatch, `${FIGURE_ORIGIN}${SLR_APPLICATION_CONTEXT}`);

/**
 * Get the adverse action letter(s) for SLR.
 * @param uuid The identifier of app.
 */
export const getAdverseAction = (uuid: any) => async (dispatch: any) =>
  ajaxGet(GET_STUDENT_LOAN_AAL, dispatch, `${FIGURE_ORIGIN}${SLR_APPLICATION_CONTEXT}/${uuid}/disclosures/aal`);

/**
 * Download disclosure or adverse action for SLR.
 * @param uuid The identifier of app.
 * @param documentUuid The identifier of document.
 * @param type AAL or Disclosure.
 */
export const downloadDocument = (uuid: any, documentUuid: any, type: any) => async (dispatch: any) =>
  ajaxGet(
    DOWNLOAD_STUDENT_LOAN_AAL,
    dispatch,
    `${FIGURE_ORIGIN}${SLR_APPLICATION_CONTEXT}/${uuid}/disclosures/${documentUuid}/download/${type}?contentDisposition=inline`,
    { responseType: 'arraybuffer' }
  );

export default {
  getApplications,
  getAdverseAction,
  downloadDocument,
};
