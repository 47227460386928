import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { FeeResponse, TxRequest, TxResponse } from 'types';
import { AxiosError } from 'axios';
import { FIGURE_ORIGIN, axios } from 'constant';

const CONTEXT = '/service-wallet/secure/api/v1/tx';

export const useEstimate = (options?: UseMutationOptions<FeeResponse, AxiosError, TxRequest>) =>
  useMutation(async (tx) => {
    const { data } = await axios.post<FeeResponse>(`${FIGURE_ORIGIN}${CONTEXT}/estimate`, tx);
    return data;
  }, options);

export const useBroadcast = (options?: UseMutationOptions<TxResponse, AxiosError, TxRequest>) =>
  useMutation(async (tx) => {
    const { data } = await axios.post<TxResponse>(`${FIGURE_ORIGIN}${CONTEXT}/broadcast`, tx);
    return data;
  }, options);
