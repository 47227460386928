import { FIGURE_ORIGIN, IS_DEV } from 'constant';
import { ProductType } from 'services/lending-engine/types';

const PL_PATH = `${FIGURE_ORIGIN}${import.meta.env.VITE_PERSONAL_LOAN_PATH}`;

export const PL_START_URL = `${PL_PATH}/applications/start`;
export const PL_DOCUMENTS_URL_IN_PROGRESS = (uuid: string) => `${PL_PATH}/applications/${uuid}/signing/disclosures`;
export const PL_APP_CONTINUE_URL = (uuid: string) => `${PL_PATH}/applications/${uuid}`;

const CL_PATH = `${FIGURE_ORIGIN}${import.meta.env.VITE_CRYPTO_LOAN_PATH}`;

export const CL_START_URL = `${CL_PATH}/start`;
export const CL_DOCUMENTS_URL_IN_PROGRESS = (uuid: string) => `${CL_PATH}/${uuid}/documents`;
export const CL_APP_CONTINUE_URL = (uuid: string) => `${CL_PATH}/${uuid}`;
export const CL_APP_COLLATERAL_RELEASE_URL = (uuid: string) => `${CL_PATH}/${uuid}/collateral-release`;

const CASHOUT_PATH = `${FIGURE_ORIGIN}${import.meta.env.VITE_CASHOUT_REFINANCE_PATH}`;
const CASHOUT_V2_PATH = `${FIGURE_ORIGIN}${import.meta.env.VITE_CASHOUT_REFINANCE_V2_PATH}`;

export const CASHOUT_START_URL = `${CASHOUT_PATH}/applications/start`;
export const CASHOUT_CONTINUE_URL = (uuid: string) => `${CASHOUT_PATH}/applications/${uuid}`;
export const CASHOUT_V2_CONTINUE_URL = (uuid: string) => `${CASHOUT_V2_PATH}/applications/${uuid}`;
export const CASHOUT_DOCUMENTS_URL = (uuid: string) => `${CASHOUT_PATH}/applications/${uuid}/documents`;

const SLR_PATH = `${FIGURE_ORIGIN}${import.meta.env.VITE_SLR_PATH}`;

export const SLR_ACCOUNT_URL = (uuid: string) => `${SLR_PATH}/servicing/${uuid}`;
export const SLR_DOCUMENTS_URL = (uuid: string) => `${SLR_PATH}/servicing/${uuid}/documents`;

const PIGGYBACK_PATH = `${FIGURE_ORIGIN}${import.meta.env.VITE_PIGGYBACK_PATH}`;
export const PIGGYBACK_DOCUMENTS_URL = (uuid: string) => `${PIGGYBACK_PATH}/${uuid}/document/disclosures`;
export const PIGGYBACK_CONTINUE_URL = (uuid: string) => `${PIGGYBACK_PATH}/${uuid}`;

export const GRAPHQL_DATOCMS = 'https://graphql.datocms.com/';

const PURCHASE_PATH = `${FIGURE_ORIGIN}${import.meta.env.VITE_PURCHASE_PATH}`;
export const PURCHASE_START_URL = `${PURCHASE_PATH}/applications/start`;
export const PURCHASE_DOCUMENTS_URL = (uuid: string) => `${PURCHASE_PATH}/applications/${uuid}/documents`;
export const PURCHASE_CONTINUE_URL = `${PURCHASE_PATH}/applications/continue`;

const LENDING_ENGINE_PATH = `${FIGURE_ORIGIN}/account/application`;
export const LENDING_ENGINE_START_URL = (product: ProductType) => `${LENDING_ENGINE_PATH}/start/${product}`;
export const LENDING_ENGINE_DOCUMENTS_URL = (uuid: string) => `${LENDING_ENGINE_PATH}/${uuid}/documents`;
export const LENDING_ENGINE_CONTINUE_URL = (uuid: string) => `${LENDING_ENGINE_PATH}/${uuid}/`;

const withUuid = (suffix: string) => (uuid: string) => `/${uuid}/${suffix}`;
export const SERVICING_DOCUMENTS_URL = withUuid('documents');
export const SERVICING_MAKE_PAYMENT_URL = withUuid('payment');
export const SERVICING_STATEMENTS_URL = withUuid('statements');
export const SERVICING_NOTICES_URL = withUuid('notices');
export const SERVICING_REDRAW_URL = withUuid('redraw');
export const SERVICING_ACCOUNT_URL = (uuid: string) => `/${uuid}/`;
export const SERVICING_BANK_ACCOUNTS_URL = withUuid('bank-accounts');
export const SERVICING_SETUP_AUTOPAY_URL = (uuid: string, disbursementAccountUuid: string) =>
  `/${uuid}/bank-accounts/${disbursementAccountUuid}/current/autopay`;
export const SERVICING_MARGIN_CALL_DEPOSIT_URL = withUuid('margin-call/deposit');
export const SERVICING_CRYPTO_COLLATERAL_RELEASE_URL = withUuid('collateral/release');

export const CRYPTO_MORTGAGE_MARKETING_URL = `${FIGURE_ORIGIN}/mortgage/crypto-mortgage`;

// base API paths
export const PASSPORT_API_BASE = `${FIGURE_ORIGIN}/service-passport/secure/api`;
export const WALLET_EXTERNAL_API_BASE = `${FIGURE_ORIGIN}/service-wallet/external/api`;

export const ORIGIN = IS_DEV ? `https://test.figure.com` : window.location.origin;
export const HELOC_ROOT = `${ORIGIN}${import.meta.env.VITE_HELOC_PATH}`;

export const HELOC_SERVICING_PATHS = {
  ORIGIN,
  SERVICING_PATH: `${ORIGIN}${import.meta.env.VITE_SERVICING_PATH}`,
  HELOC_START_URL: `${HELOC_ROOT}/register`,
  HELOC_DOCUMENTS_URL: (uuid: string) => `${HELOC_ROOT}/${uuid}/document/disclosures`,
  HELOC_CONTINUE_URL: (uuid: string) => `${HELOC_ROOT}/${uuid}`,
};
