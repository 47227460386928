import { UseQueryOptions } from '@tanstack/react-query';
import { AccountDetailsType, AccountType, DlobOpenOrder, OmnibusWireInRefResponse } from 'types';
import { IPassport } from 'types/passport';
import { AxiosError } from 'axios';
import type { ParsedFunds } from 'services/marketplace/parse-funds';
import { UndelegatedUi, ValidatorUi } from 'types/explorer';
import { useGetWireInRef } from './omnibus';
import { useGetOpenOrders } from './dlob';
import MarketplaceService from '../marketplace';
import { useGetWalletDetailsByAddress, useGetAllWallets } from './account';
import { useGetDelegatedAssets } from './delegated-assets';

export const useAggregatedWalletDataByAddress = (
  address: string,
  options: UseQueryOptions<any, AxiosError, any> = {
    enabled: false,
  }
): Partial<
  AccountType &
    AccountDetailsType & {
      allPassports: IPassport[] | undefined;
      dlobOpenOrders: DlobOpenOrder[];
      marketplaceFunds: ParsedFunds | undefined;
      marketplaceFundsError: AxiosError<any> | null;
      delegatedAssets: ValidatorUi[];
      undelegatedAssets: UndelegatedUi[];
      error: AxiosError | null;
      wireInRef: OmnibusWireInRefResponse;
      delegatedAssetsError: AxiosError<any> | null;
      isLoading: boolean;
    }
> => {
  const {
    data: accountDetails,
    error: accountDetailsError,
    isLoading: accountDetailsIsLoading,
  } = useGetWalletDetailsByAddress(address, options);
  const { data: allWallets, error: allWalletsError, isLoading: allWalletsIsLoading } = useGetAllWallets(options);
  const { data: wireInRef, error: wireInRefError, isLoading: wireInRefIsLoading } = useGetWireInRef(address, options);
  const { data: dlobOpenOrders, error: dlobOpenOrdersError, isLoading: dlobOpenOrdersIsLoading } = useGetOpenOrders(address, options);
  const {
    data: marketplaceFunds,
    error: marketplaceFundsError,
    isLoading: marketplaceFundsIsLoading,
  } = MarketplaceService.useFunds(address, options);
  const {
    data: delegatedAssets,
    error: delegatedAssetsError,
    isLoading: delegatedAssetsIsLoading,
  } = useGetDelegatedAssets(address, options);
  const aggregatedError =
    accountDetailsError || allWalletsError || wireInRefError || dlobOpenOrdersError || marketplaceFundsError || delegatedAssetsError;
  const aggregatedIsLoading =
    allWalletsIsLoading ||
    accountDetailsIsLoading ||
    wireInRefIsLoading ||
    dlobOpenOrdersIsLoading ||
    marketplaceFundsIsLoading ||
    delegatedAssetsIsLoading;
  if (allWallets === undefined) return { error: aggregatedError };
  return {
    ...accountDetails,
    ...(allWallets.get(address) ?? {}),
    wireInRef,
    dlobOpenOrders: dlobOpenOrders || [],
    marketplaceFunds,
    marketplaceFundsError,
    delegatedAssets: delegatedAssets?.delegatedAssets,
    undelegatedAssets: delegatedAssets?.undelegatedAssets,
    delegatedAssetsError,
    error: aggregatedError,
    isLoading: aggregatedIsLoading,
  };
};
