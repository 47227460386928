import { useNavigate } from 'react-router';
import { usePrivateLabelContent } from '@figure/frontlib-private-label-gui';
import { cssVar, Main, GlobalProps, Div, media, OverlaySpinnerContainer, Z_INDEX_NAV } from '@figure/figure-frontlib-gui';
import { NotificationHeader } from '@figure/frontend-navi';
import { css } from 'styled-components';
import { Helmet } from 'react-helmet-async';
import Nav from '../Nav';

type PageProps = {
  documentTitle?: string;
  showNav?: boolean;
  showNotifications?: boolean;
  loading?: boolean;
} & GlobalProps;

const defaultSpinnerCss = (showNav: boolean) =>
  css`
    position: fixed;
    z-index: ${Z_INDEX_NAV - 1};
    ${media.XL`
      ${showNav && `left: 13.5rem;`}
    `}
  `;
const pageContainerCss = css`
  max-width: 48.75rem;
  margin-left: auto;
  margin-right: auto;
  ${media.XL`
    width: 48.75rem;
  `}
`;

const Page = ({ documentTitle = '', showNav = true, showNotifications = true, children, ...rest }: PageProps) => {
  const navigate = useNavigate();
  const notificationZIndex = 999;
  const { companyNameShort } = usePrivateLabelContent();
  const documentName = `${companyNameShort} | Dashboard`;
  return (
    <>
      <Helmet>
        <title>{`${documentName}${documentTitle ? ` | ${documentTitle}` : ``}`}</title>
      </Helmet>
      <Main
        $css={css`
          position: relative;
          background-color: ${cssVar('colors.background.light')};
          width: 100%;
          box-sizing: border-box;
          padding: calc(${cssVar('spacings.l')} + 3.75rem) ${cssVar('spacings.s')};
          min-height: 150vh;
          ${media.MD`
          padding: ${cssVar('spacings.m')} ${cssVar('spacings.s')};
        `}
          ${media.XL`
          width: unset;
          ${showNav && `margin-left: 13.5rem`};
          padding: ${cssVar('spacings.2xl')} 5rem;
        `}
        `}
        {...rest}
      >
        <OverlaySpinnerContainer isLoading={false} bgColor={cssVar('colors.background.light')} spinnerCss={defaultSpinnerCss(showNav)}>
          {showNav && <Nav />}
          {showNotifications && (
            <NotificationHeader
              viewAllProps={{
                onClick: () => navigate('/notifications'),
              }}
              $css={css`
                display: none;
                h2 {
                  font-size: ${cssVar('typography.fontSize.s')};
                  font-weight: ${cssVar('typography.fontWeight.bold')};
                }
                ${media.XL`
              display: block;
              float: right;
              z-index: ${notificationZIndex};
            `}
              `}
            />
          )}

          <Div $css={pageContainerCss}>{children}</Div>
        </OverlaySpinnerContainer>
      </Main>
    </>
  );
};

export default Page;
