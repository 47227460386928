import styled from 'styled-components';
import { CSSProp } from '@figure/figure-frontlib-gui';

const Stack = styled.div<{ gap?: string } & CSSProp>`
  ${({ $css }) => $css && $css}
  > * + * {
    margin-top: ${({ gap = '0.625rem' }) => gap};
  }
`;

export default Stack;
