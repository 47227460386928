import axiosLib, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { WINDOW_MESSAGES, useWalletService, WalletService } from '@provenanceio/wallet-lib';
import { FIGURE_ORIGIN, axios, queryClient, WALLET_EXTERNAL_API_BASE } from 'constant';
import { useQuery, useMutation } from '@tanstack/react-query';
import type { AccountType } from 'types';
import { ACCOUNT_QUERIES, useGetAllWallets } from 'services/wallet/account';
import { getAuthorizedWallet } from './utils';

import type { Invitation, InvitationRequest, Role, RBACInvitation } from './types';

const authStore = new Map<string, { val: string; exp: number }>();

const AUTH_BASE = `${FIGURE_ORIGIN}/service-wallet/secure/api/v1`;
const INVITATION_BASE = `${FIGURE_ORIGIN}/service-wallet/wallet/api/v1`;
const RBAC_BASE = `${import.meta.env.VITE_FIGURE_TECH_ORIGIN}/rbac/api/v1`;

function calcExp(date: Date) {
  return date.getTime() + 12 * 3600 * 1000;
}

const getSendHook = (instance: AxiosInstance, withToken: (token: string) => AxiosRequestConfig) =>
  function useSendHook() {
    const getWalletToken = useGetWalletToken();

    return async function sendRequest(options: AxiosRequestConfig, walletAddress: string, authorizedAddress?: string) {
      const token = await getWalletToken(walletAddress, authorizedAddress);

      const { data } = await instance({
        ...withToken(token),
        ...options,
      });

      return data;
    };
  };

const useSendRequest = getSendHook(axios, (token) => ({
  headers: {
    'wallet-authorization': `Bearer ${token}`,
  },
}));

const useSendRBACRequest = getSendHook(axiosLib, (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
}));

async function refreshFigureToken(expiredToken: string) {
  const { data: token } = await axios.post(
    `${AUTH_BASE}/jwt/refresh`,
    {},
    {
      headers: {
        'x-old-jwt': expiredToken,
      },
    }
  );

  return token;
}

const getFigureToken = async (address: string): Promise<string> => {
  const cached = authStore.get(address);

  if (cached) {
    if (cached.exp > new Date().getTime()) {
      return cached.val;
    }

    const token = await refreshFigureToken(cached.val);
    authStore.set(address, {
      exp: calcExp(new Date()),
      val: token,
    });

    return token;
  }

  const { data: token } = await axios.post(`${AUTH_BASE}/wallets/${address}/login`);
  authStore.set(address, {
    exp: calcExp(new Date()),
    val: token,
  });

  return token;
};

type FinalizeTokenObj = {
  walletService: WalletService;
  toSign: string;
  address: string;
  name: string;
};

async function finalizeProvToken({ walletService, toSign, address, name }: FinalizeTokenObj) {
  const id = 'access-manage-users';

  walletService.initialize({
    address,
    keychainAccountName: name,
  });

  walletService.sign({
    payload: new TextEncoder().encode(toSign),
    id,
    title: 'Manage Users',
    description: 'Sign to access your granted wallet roles.',
  });

  const signedBytes = await new Promise((resolve, reject) => {
    walletService.addEventListener(WINDOW_MESSAGES.SIGNATURE_COMPLETE, (data: any) => {
      if (data.message?.id === id) {
        resolve(data.message.signedPayload);
      } else {
        reject(new Error(`expecting message with id: ${id}, but got: ${data.message?.id}`));
      }
      walletService.removeEventListener(WINDOW_MESSAGES.SIGNATURE_COMPLETE);
    });
    walletService.addEventListener(WINDOW_MESSAGES.CLOSE, () =>
      reject(new Error('You must sign the request to view your wallet grants. Please refresh the page and try again.'))
    );
  });

  const { data: token } = await axios.post(`${AUTH_BASE}/wallet/jwt/sign`, {
    data: toSign,
    signature: [].map.call(signedBytes, (val: number) => val),
  });

  authStore.set(address, {
    val: token,
    exp: calcExp(new Date()),
  });

  return token;
}

const useGetProvenanceToken = () => {
  const { walletService } = useWalletService(import.meta.env.VITE_PROVENANCE_WALLET_URL!);

  return async function getProvenanceToken(address: string, name: string) {
    const cached = authStore.get(address);

    if (cached) {
      if (cached.exp > new Date().getTime()) {
        return cached.val;
      }

      // get refresh
      const { data: tokenHeader } = await axios.post(
        `${AUTH_BASE}/wallet/jwt/prepare-refresh`,
        {},
        {
          headers: {
            'x-old-jwt': cached.val,
          },
        }
      );

      return finalizeProvToken({ walletService, toSign: tokenHeader, address, name: name as string });
    }

    // get original
    const { data: tokenHeader } = await axios.post(`${AUTH_BASE}/wallets/${address}/prepare-jwt`);

    return finalizeProvToken({ walletService, toSign: tokenHeader, address, name: name as string });
  };
};

export const useGetWalletToken = () => {
  const getProvenanceToken = useGetProvenanceToken();
  const { data: allWallets } = useGetAllWallets();

  return function getWalletToken(walletAddress: string, authorizedAddress?: string): Promise<string> {
    const authorizedWallet = getAuthorizedWallet(allWallets?.get(walletAddress) as AccountType, authorizedAddress);
    if (!authorizedWallet) throw new Error('not authorized');

    if (authorizedWallet.type === 'PROVENANCE') {
      return getProvenanceToken(authorizedWallet.address, authorizedWallet.name);
    }

    return getFigureToken(authorizedWallet.address);
  };
};

export const useGetSentInvitations = (address: string, authorizedAddress?: string) => {
  const sendRequest = useSendRequest();
  const { data: invitations, isLoading } = useQuery<Invitation[]>(
    ['wallets', address, 'sentInvitations'],
    () =>
      sendRequest(
        {
          url: `${INVITATION_BASE}/wallets/${address}/invitations?application=marketplace&status=PENDING,DECLINED`,
        },
        address,
        authorizedAddress
      ),
    {
      keepPreviousData: true,
    }
  );

  return {
    invitations,
    isLoading,
  };
};

type GrantedRole = {
  name: string;
  email: string;
  delegatedRoles: {
    address: string;
    name: string;
    roles: {
      name: string;
      status: 'GRANTED' | 'PENDING_GRANT' | 'PENDING_REVOKE';
    }[];
  };
};

export const useGetGrantedRoles = (address: string, authorizedAddress?: string) => {
  const sendRequest = useSendRequest();

  const { data: roles, isLoading } = useQuery(
    ['wallets', address, 'grantedRoles'],
    () =>
      sendRequest(
        {
          url: `${INVITATION_BASE}/wallets/${address}/applications/marketplace/users?status=PENDING_GRANT,PENDING_REVOKE,GRANTED`,
        },
        address,
        authorizedAddress
      )
        .then((roles) => roles.filter((role: any) => role.name !== 'Figure'))
        .then((roles: GrantedRole[]) =>
          roles.map((role) => {
            const [firstName, ...rest] = role.delegatedRoles.name.split(' ');

            return {
              address: role.delegatedRoles.address,
              email: role.email,
              firstName,
              lastName: rest.join(' '),
              role: role.delegatedRoles.roles.find((role) => role.status === 'GRANTED')?.name,
              status: 'ACCEPTED',
              pendingRevoke: role.delegatedRoles.roles.find((role) => role.status === 'PENDING_REVOKE')?.name,
              pendingGrant: role.delegatedRoles.roles.find((role) => role.status === 'PENDING_GRANT')?.name,
            };
          })
        ),
    {
      keepPreviousData: true,
    }
  );

  return {
    roles,
    isLoading,
  };
};

export const useRevokeRole = (grantingAddress: string, role: Role, authorizedAddress?: string) => {
  const sendRequest = useSendRBACRequest();

  return useMutation(
    (address: string) =>
      sendRequest(
        {
          url: `${RBAC_BASE}/subjects/${address}/revoke`,
          method: 'post',
          data: {
            address: grantingAddress,
            application: 'marketplace',
            role,
          },
        },
        grantingAddress,
        authorizedAddress
      ),
    {
      onSuccess: () => queryClient.invalidateQueries(['wallets', grantingAddress, 'grantedRoles']),
    }
  );
};

export const useCancelRoleChange = (grantingAddress: string, authorizedAddress?: string) => {
  const sendRequest = useSendRBACRequest();

  return useMutation(
    ({ address, role }: { address: string; role: Role }) =>
      sendRequest(
        {
          url: `${RBAC_BASE}/subjects/${grantingAddress}/applications/marketplace/pending/cancel`,
          method: 'post',
          data: {
            address,
            status: 'PENDING_REVOKE',
            role,
          },
        },
        grantingAddress,
        authorizedAddress
      ),
    {
      onSuccess: () => queryClient.invalidateQueries(['wallets', grantingAddress, 'grantedRoles']),
    }
  );
};

type RoleUpdate = {
  address: string;
  role: Role;
};

// note: grant endpoint functions as update in this capacity
// if app allows multiple roles (marketplace doesn't), new roles get added
// for single-role apps, role is overwritten
// https://test.figure.tech/rbac/documentation/swagger-ui/index.html#/Subject/grantRole
// these requests don't use the identity JWT
export const useUpdateRole = (address: string, authorizedAddress?: string) => {
  const sendRequest = useSendRBACRequest();

  return useMutation(
    (update: RoleUpdate) =>
      sendRequest(
        {
          url: `${RBAC_BASE}/subjects/${update.address}/grant`,
          method: 'post',
          data: {
            application: 'marketplace',
            address,
            role: update.role,
          },
        },
        address,
        authorizedAddress
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['wallets', address, 'grantedRoles']);
      },
    }
  );
};

export const useSendInvitation = (address: string, authorizedAddress?: string) => {
  const sendRequest = useSendRequest();

  return useMutation(
    (data: InvitationRequest) =>
      sendRequest(
        {
          url: `${INVITATION_BASE}/invitations`,
          method: 'post',
          data: {
            ...data,
            wallet: address,
            application: 'marketplace',
          },
        },
        address,
        authorizedAddress
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['wallets', address, 'sentInvitations']);
      },
    }
  );
};

export const useResendInvitation = (address: string, authorizedAddress?: string) => {
  const sendRequest = useSendRequest();

  return useMutation((uuid: string) =>
    sendRequest(
      {
        url: `${INVITATION_BASE}/invitations/${uuid}/resend`,
        method: 'post',
      },
      address,
      authorizedAddress
    )
  );
};

export const useUpdateInvitation = (address: string, invitationUuid: string, authorizedAddress?: string) => {
  const sendRequest = useSendRequest();

  return useMutation(
    (role: Role) =>
      sendRequest(
        {
          url: `${INVITATION_BASE}/invitations/${invitationUuid}`,
          method: 'patch',
          data: {
            role,
          },
        },
        address,
        authorizedAddress
      ),
    {
      onSuccess: () => queryClient.invalidateQueries(['wallets', address, 'invitationsSent']),
    }
  );
};

export const useAcceptInvitation = () => {
  const sendRequest = useSendRequest();

  return useMutation(
    ({ address, invitationUuid }: { address: string; invitationUuid: string }) =>
      sendRequest(
        {
          url: `${INVITATION_BASE}/invitations/${invitationUuid}/accept`,
          method: 'post',
        },
        address
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ACCOUNT_QUERIES.GET_ALL_ACCOUNTS]);
      },
    }
  );
};

export const useRevokeInvitation = (address: string, authorizedAddress?: string) => {
  const sendRequest = useSendRequest();

  return useMutation(
    (uuid: string) =>
      sendRequest(
        {
          url: `${INVITATION_BASE}/invitations/${uuid}/cancel`,
          method: 'post',
        },
        address,
        authorizedAddress
      ),
    {
      onSuccess: () => queryClient.invalidateQueries(['wallets', address, 'sentInvitations']),
    }
  );
};

export const useCheckInvitationsSetup = (address: string, authorizedAddress?: string) => {
  const sendRequest = useSendRequest();

  return () =>
    sendRequest(
      {
        url: `${INVITATION_BASE}/wallets/${address}/applications/marketplace/setup/invitations`,
        method: 'post',
      },
      address,
      authorizedAddress
    );
};

export const useGetInvitation = (uuid: string) =>
  useQuery(['rbacInvitations', uuid], () =>
    axios.get<RBACInvitation>(`${WALLET_EXTERNAL_API_BASE}/v1/invitations/${uuid}`).then((r) => r.data)
  );

type Application = {
  name: string;
  permissions: string[];
};

type Grant = {
  address: string;
  name: string;
  applications: Application[];
};

type GrantResponse = {
  address: string;
  name: string;
  grants: Grant[];
};

export const useGetSubjectPermissions = (wallet: AccountType, authorizedAddress?: string) => {
  const sendRequest = useSendRBACRequest();

  return useQuery(['grants', wallet?.address, authorizedAddress], async () => {
    if (!wallet) return [];

    if (!wallet.shared) {
      return ['READ_SUBJECT', 'READ_GRANTED_ROLES', 'READ_DELEGATED_ROLES', 'GRANT_ROLE', 'REVOKE_ROLE'];
    }

    const authorizedWallet = getAuthorizedWallet(wallet, authorizedAddress);

    return sendRequest(
      {
        url: `${RBAC_BASE}/subjects/${authorizedWallet!.address}/grants`,
      },
      wallet.address,
      authorizedAddress
    ).then(
      (data: GrantResponse) =>
        data.grants
          .find((grant) => grant.address === wallet.address)
          ?.applications.find((application) => application.name === 'marketplace')?.permissions ?? []
    );
  });
};

export const logOutRBAC = async () => {
  authStore.clear();
  await axios.post(`${AUTH_BASE}/wallet/logout`);
  queryClient.invalidateQueries(['wallets']);
};
