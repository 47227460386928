import * as account from './account';
import * as aggregatedWalletState from './aggregated-wallet-state';
import * as consent from './consent';
import * as omnibus from './omnibus';
import * as dlob from './dlob';
import * as transaction from './transaction';
import * as signing from './signing';
import * as delegatedAssets from './delegated-assets';

export default {
  ...aggregatedWalletState,
  ...account,
  ...omnibus,
  ...consent,
  ...dlob,
  ...transaction,
  ...signing,
  ...delegatedAssets,
};
