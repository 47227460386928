import { Iframe, Modal, useModal } from '@figure/figure-frontlib-gui';
import { css } from 'styled-components';
import type { VerificationToken } from 'types';

const MODAL_ID = 'TWO_FACTOR';

type TokenPayload = Partial<{
  totpToken: string;
  verificationToken: VerificationToken;
}>;

type TwoFactorModalOptions = {
  onSuccess: (payload: TokenPayload) => void;
  onError: (e: Error) => void;
  title: string;
};

const useTwoFactorModal = () => {
  const { openDynamic, closeModal } = useModal(MODAL_ID);

  return ({ onSuccess, onError, title }: TwoFactorModalOptions) => {
    let code: undefined | TokenPayload;

    const listener = (e: MessageEvent<TokenPayload>) => {
      const { totpToken, verificationToken } = e.data;

      if (totpToken) {
        code = { totpToken };
      } else if (verificationToken) {
        code = { verificationToken };
      }

      if (code) {
        window.removeEventListener('message', listener);
        onSuccess(code);
        closeModal();
      }
    };

    window.addEventListener('message', listener);

    openDynamic(
      <Modal modalId={MODAL_ID} title={title}>
        <Iframe
          $css={css`
            width: 100%;
            border: 0;
            min-height: 37.5rem;
          `}
          src="/dashboard/2fa?isIframe=true"
          title={title}
        />
      </Modal>,
      'root',
      () => {
        if (!code) {
          window.removeEventListener('message', listener);
          onError(new Error('Code not received!'));
        }
      }
    );
  };
};

export default useTwoFactorModal;
