import { CoinAsObject } from '@provenanceio/wallet-lib';
import React, { ReactNode } from 'react';

export type GlobalProps = {
  className?: string;
  as?: keyof HTMLElementTagNameMap;
  children?: ReactNode;
} & React.AllHTMLAttributes<HTMLElement>;

export enum WalletAccountType {
  INMEMORY = 'INMEMORY',
  FIGURE = 'FIGURE',
  OLDNET = 'OLDNET',
  ADNALES = 'ADNALES',
  PROVENANCE = 'PROVENANCE',
  SELF_CUSTODY = 'SELF_CUSTODY',
}

export type BalanceType = {
  amount: number;
  denom: string;
};

export enum PassportStatus {
  VALID = 'VALID',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  CANCELLED_STALE = 'CANCELLED_STALE',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type PassportType = {
  accreditationRequestUuid?: string;
  authorizedSigner: string;
  country: string;
  expirationDate: string;
  label: string;
  orgType?: OrgType;
  pending: boolean;
  siteLink: string;
  state?: string;
  status: PassportStatus;
};

export type PendingPassports = {
  [uuid: string]: PassportType[];
};

export type AccountType = {
  active: boolean;
  address: string;
  default: boolean;
  name: string;
  ownerUuid: string;
  txCallbackUrl: string;
  publicKeyB64: string;
  uuid: string;
  walletType: WalletAccountType;
  legalName: string;
  orgType: OrgType;
  shared?: boolean;
  authorized: AuthorizedWallets;
};

export type AuthorizedWallet = {
  address: string;
  name: string;
  legalName: string;
  type: WalletAccountType;
};

// every account has at least one authorized wallet
type AuthorizedWallets = {
  0: AuthorizedWallet;
} & AuthorizedWallet[];

export type AllAccountsResponse = {
  account: AccountType;
  authorizedAccount: AuthorizedWallets;
}[];
export type AccountDetailsType = { account: AccountType; passports: PassportType[]; holdings: BalanceType[] } | null;

export type AccountNameRequest = {
  name?: string;
  isDefault?: boolean; // default key word cant be used
};

export type ExternalAccountRequest = AccountNameRequest &
  AccountType & {
    publicKeyB64: string;
    randomB64: string;
    signedB64: string;
  };

export type ConsentType = {
  name: string;
  type: string;
  url: string;
  uuid: string;
};

export type SignConsentType = {
  consentUuid: string;
  signerName: string;
};

export enum KycStatus {
  VALID = 'VALID',
  MISSING_CONSENTS = 'MISSING_CONSENTS',
  MISSING_PASSPORT = 'MISSING_PASSPORT',
  PENDING_PASSPORT = 'PENDING_PASSPORT',
}

type Address = {
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  address2: string;
  address3: string;
};

export type OmnibusWireInRef = {
  wireObiReference: string;
  accountNumber?: string;
  routingNumber?: string;
  financialInstitution?: string;
  beneficiaryBank: string;
  beneficiaryBankAddress: Address;
  originator: string;
  swiftCode: string;
};

export type OmnibusWireInRefResponse = {
  kycStatus: KycStatus;
  omnibusWireInRef?: OmnibusWireInRef;
};

export type OmnibusResponse = {
  bankAccountUuid: string;
  accountName: string;
  institution: string;
  lastFourAccountNumber: string;
  customerName: string;
};

export type OmnibusWireOutCreateRequest = {
  // walletAddress: string; // TODO khigh is needed?
  accountName: string;
  accountNumber: string;
  routingNumber: string;
  institution: string;
  customerName: string; // account holder name
  wireInstructions: string;
  street: string;
  city: string;
  state: string;
  postalCode: string;
  country?: string;
  swiftRequest?: {
    swiftId?: string;
    street?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country?: string;
  };
};

export type OmnibusWireOutRefRequest = {
  bankAccountUUID: string;
  redemptionAmount: number;
};

export type OmnibusWireOutRefResponse = {
  kycStatus: KycStatus;
  msgSend?: MsgSendRedemption;
};

export type OmnibusWireOutEmailRequest = {
  txhash: string;
  amount: number;
  email: string;
};

export type MsgSendRedemption = {
  from: string;
  to: string;
  amount: number;
  denom: string;
  memo: string;
};

export type ExchangeRequest = {
  address: string;
};

export type ExchangeResponse = {
  url: string;
};

export type DailyHashPriceResponse = {
  contractAddress: string;
  displayVolumeTraded: string;
  highDisplayPricePerDisplayUnit: string;
  highPricePerUnit: string;
  latestDisplayPricePerDisplayUnit: string;
  latestPricePerUnit: string;
  lowDisplayPricePerDisplayUnit: string;
  lowPricePerUnit: string;
  volumeTraded: string;
};

export enum OrderStatus {
  OPEN = 'OPEN',
  COMPLETE = 'COMPLETE',
  CANCELED = 'CANCELED',
}
export enum OrderType {
  ASK = 'ASK',
  BID = 'BID',
}
export type DlobOrder = {
  amount: string;
  baseDenom: string; // nhash
  baseDisplay: string; // hash
  contractAddress: string;
  created: string;
  displayAmount: string;
  displayPricePerDisplayUnit: string;
  pricePerUnit: string;
  quoteDenoms: string[]; // ['exchangesc']
  quoteDisplays: string[]; // ['usd']
  remainingQuantity: string;
  remainingDisplayAmount: string;
  status: OrderStatus;
  type: OrderType;
  uuid: string;
};
export type DlobOrderResponse = {
  data: DlobOrder[];
  pagination: {
    page: number;
    pageCount: number;
    size: number;
    totalCount: number;
  };
};
export type DlobOpenOrder = {
  uuid: string;
  date: string;
  realDate: Date;
  asset: string; // hash
  type: string;
  amount: string;
  quantity: string;
  remainingQuantity: string;
  price: string;
};
/**
 * position modal view
 */
export type CoinPosition = {
  coin: string;
  displayBalance?: string;
  displayAmount: string;
} & CoinAsObject;

export type Alias = { uuid: string; value: string; type: string; auth: boolean; verified: boolean };
export type Aliases = Alias[];

export type VerificationToken = {
  token: string;
  alias: Alias;
};
export type TxRequest = {
  msgAnyB64: string;
  address: string;
  memo?: string;
  verificationToken?: VerificationToken;
  totpToken?: string;
};

export type FeeResponse = {
  total: number;
  fees: number;
};

export type TxResponse = {
  txhash: string;
  block: number;
  txFee: number;
  recipientAddress: string;
  senderAddress: string;
  amount: number;
  denom: string;
};

export type SignRequest = {
  address: string;
  bytesB64: string;
};

export type SignResponse = {
  publicKeyB64: string;
  signedB64: string;
};

export enum TRANSACTION_TYPE {
  PRIMARY_RAISE = 'PRIMARY_RAISE',
  CAPITAL_FUNDS = 'CAPITAL_FUNDS',
  OPEN_ENDED_FUND = 'OPEN_ENDED_FUND',
  SECONDARY_MARKET = 'SECONDARY_MARKET',
  PRIVATE_REIT = 'PRIVATE_REIT',
}

export type FundDetail = {
  uuid: string;
  auctionUuid: string;
  denom: string;
  title: string;
  status: string;
  date: string;
  type: TRANSACTION_TYPE;
  quantity: number;
  currentPrice: number;
  amount: number;
  amountInvested: number;
  originalCommitment: number;
  acceptedCommitment: number | undefined;
  unpaidPrincipalBalance: number;
  transactions: Array<FundTransaction>;
  subscriptionUuid: string;
};

export type FundsResponse = {
  funds?: FundDetail[];
};

export type FundTransaction = {
  uuid: string;
  title: string;
  date: Date;
  dueDate: Date;
  type: TRANSACTION_TYPE;
  status: string;
  amount: number;
  quantity: number;
};

export type SecurityQuestion = {
  uuid: string;
  question: string;
  tier: number;
};

export type SecurityAnswerRequest = {
  securityQuestionUuid: string;
  answer: string;
};

// used for wallet term display, returned as orgType in passport
export enum OrgType {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS = 'BUSINESS',
}
