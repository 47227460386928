import { handleActions } from 'redux-actions';
import { REQUEST, SUCCESS } from '@figure/frontlib-onboarding-redux/lib/actions/http-actions';
import { GET_STUDENT_LOAN_REFINANCES, GET_STUDENT_LOAN_AAL } from '../actions/slr-actions';

type SLRState = {
  applicationsByUuid: {
    [key: string]: any;
  };
  loading: boolean;
};

export const initialState = {
  applicationsByUuid: {},
  loading: false,
};

const slrReducer = handleActions(
  {
    [`${GET_STUDENT_LOAN_REFINANCES}_${REQUEST}`](state = initialState) {
      return {
        ...state,
        loading: true,
      };
    },
    [`${GET_STUDENT_LOAN_REFINANCES}_${SUCCESS}`](state, action) {
      const applications = action.payload as any;

      return {
        ...state,
        loading: false,
        applicationsByUuid: {
          ...state.applicationsByUuid,
          ...applications.reduce((result: any, a: any) => ({ ...result, [a.uuid]: a }), {}),
        },
      };
    },
    [`${GET_STUDENT_LOAN_AAL}_${REQUEST}`](state = initialState) {
      return {
        ...state,
        loading: true,
      };
    },
    [`${GET_STUDENT_LOAN_AAL}_${SUCCESS}`](state: SLRState, action) {
      const { applicationUuid: uuid } = action.payload as any;
      const application = state.applicationsByUuid[uuid] || {};

      return {
        ...state,
        loading: false,
        applicationsByUuid: {
          ...state.applicationsByUuid,
          [uuid]: {
            ...application,
            aal: action.payload,
          },
        },
      };
    },
  },
  initialState
);

export default slrReducer;
