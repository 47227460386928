import type { AxiosRequestConfig } from 'axios';
import { initialState as identityInitialState } from '@figure/frontlib-onboarding-redux/lib/reducers/identity-reducer';
import { initialState as profileInitialState } from '@figure/frontlib-onboarding-redux/lib/reducers/profile-reducer';
import { applyMiddleware, createStore, compose } from 'redux';
import { identityActions, createAppSocketMiddleware, createUserEventSocketMiddleware, axios } from '@figure/frontlib-onboarding-redux';
import thunk from 'redux-thunk';
import { FIGURE_ORIGIN, IS_DEV, IS_PROD } from 'constant';
import { getToken } from 'services/identity';
import { getOrigin, getRedirectUrl } from '../utils';
import { rootReducer } from './rootReducer';

export const stateKey = 'LoanFlowSession';
export const magicLinkTokenKey = 'LoanFlowMagicLinkToken';

let composeEnhancers = compose;

if (IS_PROD) {
  /* eslint-disable no-underscore-dangle */
  // Note: Really awesome Redux dev tools for Chrome - https://github.com/zalmoxisus/redux-devtools-extension
  if ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  }
  /* eslint-enable no-underscore-dangle */
}

const persistedState = () => {
  const jwt = getToken();

  if (jwt) {
    const newState = {
      identityReducer: { ...identityInitialState, jwt },
    };

    const state = localStorage.getItem(stateKey);
    if (!state) return newState;

    try {
      const oldState = JSON.parse(state);

      return Object.assign(newState, {
        identityReducer: {
          ...newState.identityReducer,
          coDeeder: oldState.coDeeder,
          identity: {
            ...newState.identityReducer.identity,
            uuid: oldState.identityUuid || '',
          },
        },
        profileReducer: { ...profileInitialState, uuid: oldState.identityUuid || '' },
      });
    } catch (e) {
      console.error('Unable to parse old state', e); // eslint-disable-line no-console

      return newState;
    }
  }

  const magicLinkToken = localStorage.getItem(magicLinkTokenKey);

  if (magicLinkToken) {
    return {
      identityReducer: {
        ...identityInitialState,
        magicLink: {
          ...identityInitialState.magicLink,
          token: magicLinkToken || '',
        },
      },
    };
  }

  return {};
};

const middlewares = [];
middlewares.push(createAppSocketMiddleware(), createUserEventSocketMiddleware(), thunk);

const enhancers: any = [];
const composedEnhancers = composeEnhancers(applyMiddleware(...middlewares), ...enhancers);
const store = createStore(rootReducer, persistedState(), composedEnhancers);

// Custom header to set the Origin for white claw in case client does not include it in default Origin header
axios.defaults.headers.common['X-With-Origin'] = getOrigin();

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  const jwt = getToken();

  return jwt
    ? {
        ...config,
        headers: {
          ...(config.headers ?? {}),
          Authorization: jwt,
        },
      }
    : config;
});

axios.interceptors.response.use(
  (response: any) => {
    if (response.status === 200) {
      const { authorization } = response.headers;

      if (typeof authorization === 'string' && authorization.indexOf('Bearer ') === 0) {
        store.dispatch(identityActions.rotateJwt({ jwt: authorization }));
      }
    }

    return response;
  },
  (error: any) => {
    // have to check error object existence due to possibility of cancellations
    if (
      (error?.response?.status === 403 && error?.response?.data?.message?.toUpperCase() === 'UNAUTHENTICATED') ||
      (error?.response?.status === 401 && !error?.response.config?.url.includes('provenance/portfolio/proxy/v1/ping'))
    ) {
      const { coDeeder } = store.getState().identityReducer as any;

      store.dispatch(identityActions.timeout());
      [stateKey, magicLinkTokenKey].forEach((k) => localStorage.removeItem(k));

      if (!IS_DEV) {
        window.location.href = coDeeder
          ? `${FIGURE_ORIGIN}${import.meta.env.VITE_ONBOARDING_PATH}/heloc-signer/login`
          : getRedirectUrl();
      }
    }

    return Promise.reject(error);
  }
);

store.subscribe(() => {
  const state = store.getState() as any;

  const session = JSON.stringify({
    coDeeder: state.identityReducer.coDeeder,
    magicLinkToken: state.identityReducer.magicLink.token,
    identityUuid: state.identityReducer.identity.uuid || state.profileReducer.profile.uuid,
  });

  localStorage.setItem(stateKey, session);

  const { token } = state.identityReducer.magicLink;
  if (token) {
    localStorage.setItem(magicLinkTokenKey, token);
  }
});

export const handleJWT = () => {
  const state = store.getState();
  const { jwt } = state.identityReducer as any;

  const storeJwt = getToken();

  if ((!jwt || !storeJwt) && !IS_DEV) {
    store.dispatch(identityActions.timeout());
    window.location.href = getRedirectUrl();
  } else if (jwt) {
    // when you have a jwt, kill the magic link token; check for existence or you run into a circular dependency issue
    const { token } = (state.identityReducer as any).magicLink;
    if (token) {
      store.dispatch(identityActions.magiclinkReset());
      localStorage.removeItem(magicLinkTokenKey);
    }
  }
};

// handle multiple tabs open and log out or timeout
window.addEventListener('storage', handleJWT);

export default store;
