import { FundDetail, FundTransaction, TRANSACTION_TYPE } from 'types';
import { LinkOrButtonProps } from '@figure/figure-frontlib-gui';
import { BID_STATUS } from 'pages/Dashboard/Marketplace/constants';
import { FIGURE_ORIGIN } from 'constant';

export function getDetailsLink(auctionUuid: string, transactionType: TRANSACTION_TYPE): LinkOrButtonProps {
  let href: string;
  switch (transactionType) {
    case TRANSACTION_TYPE.OPEN_ENDED_FUND:
    case TRANSACTION_TYPE.CAPITAL_FUNDS:
    case TRANSACTION_TYPE.PRIVATE_REIT:
      href = `${FIGURE_ORIGIN}/marketplace/buyer/funds/${auctionUuid}`;
      break;
    case TRANSACTION_TYPE.PRIMARY_RAISE:
      href = `${FIGURE_ORIGIN}/marketplace/buyer/raises/${auctionUuid}`;
      break;
    case TRANSACTION_TYPE.SECONDARY_MARKET:
      href = `${FIGURE_ORIGIN}/marketplace/ats/${auctionUuid}`;
      break;
    default:
      href = `${FIGURE_ORIGIN}/marketplace/buyer/pools/${auctionUuid}`;
  }
  return {
    children: 'Details',
    sameTab: true,
    href,
  };
}

function getPendingFundsCapitalCallUuid(transactions: FundTransaction[]): string {
  const pendingCapitalCall = transactions.find((transaction: FundTransaction) => transaction.status === BID_STATUS.PENDING_FUNDS);
  return pendingCapitalCall?.uuid ?? '';
}

export function getLinks(
  status: string,
  subscription: FundDetail
): Array<LinkOrButtonProps | { atsDenom?: string; removeAutionUuid?: string }> {
  const { auctionUuid, type, uuid, denom, transactions } = subscription;
  switch (subscription.type) {
    case TRANSACTION_TYPE.PRIMARY_RAISE:
    case TRANSACTION_TYPE.OPEN_ENDED_FUND:
    case TRANSACTION_TYPE.CAPITAL_FUNDS:
    case TRANSACTION_TYPE.PRIVATE_REIT: {
      const links = [];
      if (status === BID_STATUS.INTERESTED)
        links.push({
          removeAutionUuid: auctionUuid,
        });
      links.push(getDetailsLink(auctionUuid, type));
      return links;
    }
    case TRANSACTION_TYPE.SECONDARY_MARKET: {
      const links = [];
      if ([BID_STATUS.APPROVED].includes(status.toUpperCase())) links.push({ atsDenom: denom });
      links.push(getDetailsLink(auctionUuid, type));
      return links;
    }
    default:
      switch (status) {
        case BID_STATUS.PENDING_REVIEW:
          return [
            { children: 'Modify Bid', sameTab: true, href: `${FIGURE_ORIGIN}/marketplace/buyer/pools/${auctionUuid}` },
            {
              children: 'Cancel Bid',
              sameTab: true,
              href: `${FIGURE_ORIGIN}/marketplace/buyer/pools/${auctionUuid}/bid/${uuid}/cancel`,
            },
          ];
        case BID_STATUS.PENDING_FUNDS:
          return [
            {
              children: 'Commit Capital',
              sameTab: true,
              href: `${FIGURE_ORIGIN}/marketplace/buyer/capital-calls/${getPendingFundsCapitalCallUuid(transactions)}/fund`,
            },
          ];
        case BID_STATUS.INTERESTED:
          return [
            {
              removeAutionUuid: auctionUuid,
            },
          ];
        default:
          return [];
      }
  }
}

export function getOpenOrderLinks(status: string, subscription: FundDetail): Array<LinkOrButtonProps | { removeAutionUuid?: string }> {
  const { auctionUuid, type, transactions } = subscription;
  switch (type) {
    case TRANSACTION_TYPE.PRIMARY_RAISE:
    case TRANSACTION_TYPE.OPEN_ENDED_FUND:
    case TRANSACTION_TYPE.CAPITAL_FUNDS:
    case TRANSACTION_TYPE.PRIVATE_REIT:
      switch (status) {
        case BID_STATUS.PENDING_FUNDS:
          return [
            {
              children: 'Commit Capital',
              sameTab: true,
              href: `${FIGURE_ORIGIN}/marketplace/buyer/capital-calls/${getPendingFundsCapitalCallUuid(transactions)}/fund`,
            },
            getDetailsLink(auctionUuid, type),
          ];
        case BID_STATUS.INTERESTED:
          return [
            {
              removeAutionUuid: auctionUuid,
            },
            getDetailsLink(auctionUuid, type),
          ];
        default:
          return [getDetailsLink(auctionUuid, type)];
      }
    default:
      return [];
  }
}
