import { handleActions } from 'redux-actions';
import { REQUEST, SUCCESS, FAILURE } from '@figure/frontlib-onboarding-redux/lib/actions/http-actions';
import { payloadError } from '@figure/frontlib-onboarding-redux/lib/reducers/util';
import {
  GET_CRYPTO_LOAN,
  GET_SERVICE_MARGIN_CALLS,
  GET_CRYPTO_LOAN_AAL,
  DOWNLOAD_CRYPTO_LOAN_AAL,
} from '../actions/crypto-loan-actions';

type CryptoLoanState = {
  cryptoLoansByUuid: {
    [key: string]: any;
  };
  error: string;
};

export const initialState = {
  cryptoLoansByUuid: {},
  error: '',
};

function merge(state: any, action: any) {
  if (!action.payload) {
    return {
      ...state,
      cryptoLoansByUuid: {},
      error: '',
      loading: false,
    };
  }

  const cryptoLoansByUuid = action.payload
    .map((cl: any) => {
      const { appUuid } = cl;
      const cryptoLoan = state.cryptoLoansByUuid[appUuid] || { ...cl };
      return {
        ...cryptoLoan,
      };
    })
    .reduce((result: any, cl: any) => {
      const keyValue: any = {};
      keyValue[cl.appUuid] = { ...cl };
      return { ...result, ...keyValue };
    }, {});

  return {
    ...state,
    cryptoLoansByUuid,
    loading: false,
    error: '',
  };
}

const reducer = handleActions(
  {
    [`${GET_CRYPTO_LOAN}_${REQUEST}`](state = initialState) {
      return {
        ...state,
        error: '',
        loading: true,
      };
    },
    [`${GET_CRYPTO_LOAN}_${FAILURE}`](state, action) {
      return {
        ...state,
        error: payloadError(action),
      };
    },
    [`${GET_CRYPTO_LOAN}_${SUCCESS}`](state, action) {
      return merge(state, action);
    },
    [`${GET_SERVICE_MARGIN_CALLS}_${REQUEST}`](state = initialState) {
      return {
        ...state,
        error: '',
        loading: true,
      };
    },
    [`${GET_SERVICE_MARGIN_CALLS}_${FAILURE}`](state, action) {
      return {
        ...state,
        error: payloadError(action),
      };
    },
    [`${GET_SERVICE_MARGIN_CALLS}_${SUCCESS}`](state: CryptoLoanState, action) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { assetId } = action.payload;
      const { cryptoLoansByUuid } = state;
      if (cryptoLoansByUuid[assetId]) {
        cryptoLoansByUuid[assetId] = {
          ...cryptoLoansByUuid[assetId],
          ...action.payload,
        };
      }
      return {
        ...state,
        cryptoLoansByUuid,
        loading: false,
        error: '',
      };
    },
    [`${GET_CRYPTO_LOAN_AAL}_${REQUEST}`](state = initialState) {
      return {
        ...state,
        error: '',
        loading: true,
      };
    },
    [`${GET_CRYPTO_LOAN_AAL}_${FAILURE}`](state, action) {
      return {
        ...state,
        loading: false,
        error: payloadError(action),
      };
    },
    [`${GET_CRYPTO_LOAN_AAL}_${SUCCESS}`](state: CryptoLoanState, action) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const cryptoLoanUuid = (action.meta as any).appUuid;
      const cryptoLoan = state.cryptoLoansByUuid[cryptoLoanUuid] || {};
      return {
        ...state,
        error: '',
        loading: false,
        cryptoLoansByUuid: {
          ...state.cryptoLoansByUuid,
          [cryptoLoanUuid]: {
            ...cryptoLoan,
            aalDocs: (action.payload as any).documents || [],
          },
        },
      };
    },
    [`${DOWNLOAD_CRYPTO_LOAN_AAL}_${REQUEST}`](state = initialState) {
      return {
        ...state,
        error: '',
        loading: true,
      };
    },
  },
  initialState
);

export default reducer;
