import { baseUrl } from '@figure/frontlib-onboarding-redux/lib/actions/http-actions';
import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  AccountDetailsType,
  AccountNameRequest,
  AccountType,
  AllAccountsResponse,
  ExchangeRequest,
  ExchangeResponse,
  ExternalAccountRequest,
  WalletAccountType,
} from 'types';
import { FIGURE_ORIGIN, axios } from 'constant';
import { useIdentity } from 'hooks';
import { AxiosError } from 'axios';

const withVersion = (version: string) => `/service-wallet/secure/api/${version}/account`;
const CONTEXT = withVersion('v1');
const CONTEXT_V2 = withVersion('v2');

export enum ACCOUNT_QUERIES {
  GET_ALL_ACCOUNTS = 'GET_ALL_ACCOUNTS',
  GET_ACCOUNT_DETAILS = 'GET_ACCOUNT_DETAILS',
  GET_NEXT_DEFAULT_NAME = 'GET_NEXT_DEFAULT_NAME',
}

export type Accounts = Map<string, AccountType>;

export const useGetAllWallets = (options?: UseQueryOptions<Accounts, AxiosError>) => {
  const { uuid } = useIdentity();
  return useQuery(
    [ACCOUNT_QUERIES.GET_ALL_ACCOUNTS, uuid] as QueryKey,
    async () => {
      // if (organization !== FIGURE_ORGANIZATION) return {};
      const { data } = await axios.get<AllAccountsResponse>(`${baseUrl()}${CONTEXT_V2}`);

      return new Map(
        data.map(({ account, authorizedAccount }) => [
          account.address,
          {
            ...account,
            shared: account.ownerUuid !== uuid,
            authorized: authorizedAccount,
          },
        ])
      );
    },
    options
  );
};

export const useGetWalletDetailsByAddress = (address: string, options?: UseQueryOptions<AccountDetailsType, AxiosError>) =>
  useQuery(
    [ACCOUNT_QUERIES.GET_ACCOUNT_DETAILS, address] as QueryKey,
    async () => {
      const { data } = await axios.get<AccountDetailsType>(`${baseUrl()}${CONTEXT}/details/${address}`);
      return data;
    },
    options
  );

export const useCreateHostedAccount = (options?: UseMutationOptions<AccountType, AxiosError, AccountNameRequest>) =>
  useMutation(async ({ name, isDefault }) => {
    const { data } = await axios.post<AccountType>(`${FIGURE_ORIGIN}${CONTEXT}/hosted`, {
      name,
      default: isDefault,
    });
    return data;
  }, options);

export const connectExternalAccount = async (req: Partial<ExternalAccountRequest>) => {
  const { isDefault, walletType = WalletAccountType.PROVENANCE, ...rest } = req;
  const { data } = await axios.post<AccountType>(`${FIGURE_ORIGIN}${CONTEXT}/external`, {
    ...rest,
    default: isDefault,
    walletType,
  });
  return data;
};

export const useGetNextDefaultName = (options?: UseQueryOptions<string, AxiosError>) =>
  useQuery(
    [ACCOUNT_QUERIES.GET_NEXT_DEFAULT_NAME] as QueryKey,
    async () => {
      const { data } = await axios.get<{ name: string }>(`${FIGURE_ORIGIN}${CONTEXT}/next-default-name`);
      return data.name;
    },
    options
  );

export const useDeleteAccount = (options?: UseMutationOptions<unknown, AxiosError, string>) =>
  useMutation(async (uuid) => axios.delete(`${FIGURE_ORIGIN}${CONTEXT}/delete/${uuid}`), options);

export const useUpdateAccount = (options?: UseMutationOptions<unknown, AxiosError, { walletUuid: string } & AccountNameRequest>) =>
  useMutation(
    async ({ name, isDefault, walletUuid }) =>
      axios.put(`${FIGURE_ORIGIN}${CONTEXT}/update/${walletUuid}`, {
        name,
        default: isDefault,
      }),
    options
  );

// TODO remove exchange login when we no longer use that cookie to determine current wallet
export const useAuthenticateForExchange = (options?: UseMutationOptions<unknown, AxiosError, ExchangeRequest>) =>
  useMutation(async (req) => {
    return axios.post<ExchangeResponse>(`${FIGURE_ORIGIN}${CONTEXT}/exchange/login`, req);
  }, options);
