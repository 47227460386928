import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { SecurityAnswerRequest, SecurityQuestion } from 'types';
import { FIGURE_ORIGIN, axios } from 'constant';

const CONTEXT = '/service-security-question/secure/api/v1/question';

export enum QUESTION_QUERIES {
  GET_QUESTIONS = 'GET_QUESTIONS',
  GET_QUESTIONS_BY_USER = 'GET_QUESTIONS_BY_USER',
}

export const useGetQuestions = (options?: UseQueryOptions<Array<SecurityQuestion[]>, AxiosError>) =>
  useQuery(
    [QUESTION_QUERIES.GET_QUESTIONS] as QueryKey,
    async () => {
      const { data } = await axios.get<SecurityQuestion[]>(`${FIGURE_ORIGIN}${CONTEXT}`);
      const res = data.reduce((result: Array<SecurityQuestion[]>, question) => {
        // eslint-disable-next-line no-param-reassign
        result[question.tier - 1] = [...(result[question.tier - 1] || []), question];
        return result;
      }, []);
      return res;
    },
    options
  );

export const useGetAnsweredQuestionsByUser = (options?: UseQueryOptions<SecurityQuestion[], AxiosError>) =>
  useQuery(
    [QUESTION_QUERIES.GET_QUESTIONS_BY_USER] as QueryKey,
    async () => {
      const { data } = await axios.get<any>(`${FIGURE_ORIGIN}${CONTEXT}/user`);
      return data;
    },
    options
  );

export const useCreateAnswers = (options?: UseMutationOptions<unknown, AxiosError, SecurityAnswerRequest[]>) =>
  useMutation(async (data) => axios.post<SecurityAnswerRequest[]>(`${FIGURE_ORIGIN}${CONTEXT}/create`, data), options);

export const useConfirmAnswers = (options?: UseMutationOptions<any, AxiosError, SecurityAnswerRequest[]>) =>
  useMutation(async (data) => axios.post<SecurityAnswerRequest[]>(`${FIGURE_ORIGIN}${CONTEXT}/confirm`, data), options);

export default { useGetQuestions, useGetAnsweredQuestionsByUser, useCreateAnswers };
