import type { AccountType } from 'types';
import type { Role } from './types';

export const getPreposition = (role: Role) => (role === 'Admin' ? 'an' : 'a');

export const getAuthorizedWallet = (wallet: AccountType, authorizedAddress?: string) =>
  authorizedAddress
    ? wallet.authorized.find((authorizedWallet) => authorizedWallet.address === authorizedAddress)
    : wallet.authorized[0];

export const getRoleFromGrants = (grants: string[]) => {
  if (grants.includes('GRANT_ROLE')) return 'Admin';
  if (grants.includes('EXECUTE_CAPITAL_ACTIVITY')) return 'Authorized Signatory';
  if (grants.includes('INVITE_INVESTORS')) return 'Viewer+';
  return 'Viewer';
};
