import { format } from 'date-fns';
import { AxiosError } from 'axios';
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { DailyHashPriceResponse, DlobOpenOrder, DlobOrderResponse, OrderStatus } from 'types';
import { toCapitalCase } from '@figure/figure-frontlib-gui';
import { formatMoney } from '@figure/frontlib-util/lib/numeric-formatters';
import Big from 'big.js';
import accounting from 'accounting';
import { axios, IS_PROD } from 'constant';

export enum DLOB_QUERIES {
  GET_DAILY_HASH_PRICE = 'GET_DAILY_HASH_PRICE',
  GET_OPEN_ORDERS = 'GET_OPEN_ORDERS',
}

const BASE_DLOB_URL = IS_PROD ? 'https://www.dlob.io' : 'https://test.dlob.io';
export const HASH_CONTRACT_ADDRESS = IS_PROD
  ? 'pb18vd8fpwxzck93qlwghaj6arh4p7c5n894vnu5g'
  : 'tp1059nz3h87g5t6sht44w7cyj2ekd72mrpxr76pv';

/**
 * Gets the total value of hash
 */
export const useGetDailyHashPrice = (options?: UseQueryOptions<DailyHashPriceResponse, AxiosError>) =>
  useQuery(
    [DLOB_QUERIES.GET_DAILY_HASH_PRICE] as QueryKey,
    async () => {
      const { data } = await axios.get<DailyHashPriceResponse>(
        `${BASE_DLOB_URL}/aggregator/external/api/v1/order-books/${HASH_CONTRACT_ADDRESS}/daily-price`
      );
      return data;
    },
    options
  );

export const useGetOpenOrders = (address?: string, options?: UseQueryOptions<DlobOpenOrder[] | null, AxiosError>) =>
  useQuery(
    [DLOB_QUERIES.GET_OPEN_ORDERS, address] as QueryKey,
    async () => {
      if (!address) return null;
      const { data } = await axios.get<DlobOrderResponse>(`${BASE_DLOB_URL}/aggregator/external/api/v1/addresses/${address}/orders`);

      return data.data
        ?.filter((order) => order.status === OrderStatus.OPEN)
        .map((order) => {
          const { uuid, created, baseDisplay, type, displayAmount, remainingDisplayAmount, displayPricePerDisplayUnit } = order;
          const realDate = new Date(created);
          return {
            uuid,
            realDate,
            date: format(realDate, 'MM/dd/yyyy'),
            asset: toCapitalCase(baseDisplay),
            type: toCapitalCase(type),
            price: formatMoney(displayPricePerDisplayUnit, 3),
            quantity: accounting.formatNumber(Big(displayAmount).toNumber(), undefined, ','),
            amount: formatMoney(Big(displayPricePerDisplayUnit).times(remainingDisplayAmount).toNumber(), 2),
            remainingQuantity: accounting.formatNumber(Big(remainingDisplayAmount).toNumber(), undefined, ','),
          };
        })
        .sort((a, b) => b.realDate.getTime() - a.realDate.getTime());
    },
    options
  );
