import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { SignRequest, SignResponse } from 'types';
import { AxiosError } from 'axios';
import { FIGURE_ORIGIN, axios } from 'constant';

const CONTEXT = '/service-wallet/secure/api/v1/signing';

export const useSign = (options?: UseMutationOptions<SignResponse, AxiosError, SignRequest>) =>
  useMutation(async (req) => {
    const { data } = await axios.post<SignResponse>(`${FIGURE_ORIGIN}${CONTEXT}/sign`, req);
    return data;
  }, options);
