import { useEffect } from 'react';
import * as FullStorySDK from '@fullstory/browser';
import { useIdentity } from 'hooks';
import { ProfileService } from 'services';
import { IS_PROD } from 'constant';

const FullStory = () => {
  const { identity, isLoading, organization } = useIdentity();
  const { data: profile } = ProfileService.useGetProfile({ enabled: false });

  const isAuthenticated = !!identity?.uuid && !!profile?.uuid && !isLoading;

  // Initialize Fullstory
  useEffect(() => {
    FullStorySDK.init({
      orgId: import.meta.env.VITE_FULLSTORY_ORG_ID,
      devMode: !IS_PROD,
    });
    return () => {
      FullStorySDK.shutdown();
    };
  }, []);

  // Identify the user once authenticated, add initial user vars
  useEffect(() => {
    if (isAuthenticated) {
      FullStorySDK.identify(profile?.uuid as string, {
        displayName: `${profile?.name?.firstName} ${profile?.name?.lastName}`,
        email: profile?.email,
        profileUuid: profile?.uuid,
        productType: 'frontend-dashboard',
        organization,
      });
    }
  }, [isAuthenticated, profile?.uuid, profile?.name, profile?.email, organization]);

  return null;
};

export default FullStory;
